import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import {
  Typography,
  Card,
  Box,
  CardHeader,
  CardContent,
  List,
  ListItemText,
  ListItemButton,
  useTheme,
  DialogTitle,
  Dialog,
  DialogContent,
  Stack,
  Chip,
  Divider,
} from "@mui/material";
import { fetchActionItem, fetchLeads } from "../../services/leadsAPI";
import { fetchEvents } from "../../services/eventsAPI";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Dashboard = ({ isMarketing, user }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Fetch data
  const { data: action_items } = useQuery({
    queryKey: ["action_items"], // Query key for caching and invalidation
    queryFn: fetchActionItem, // Function to fetch data
  });
  const { data: leads } = useQuery({
    queryKey: ["leads"],
    queryFn: fetchLeads,
  });
  const { data: events } = useQuery({
    queryKey: ["events"],
    queryFn: fetchEvents,
  });

  // Action item filtering
  const filteredActionItems = isMarketing
    ? action_items // Show all action_items if isMarketing is true
    : action_items?.filter((item) =>
        item.employees_involved.some(
          (employee) => employee.email === user?.email // Filter action_items where user's email is in employees_involved
        )
      );

  // Get current date
  const currentDate = new Date();

  // Filter events
  const upcomingEvents = events?.filter((item) => {
    const startDate = new Date(item.start_date); // Parse start_date
    return startDate > currentDate; // Keep only events with start_date in the future
  });

  const currentHour = new Date().getHours();
  const greeting = currentHour < 12 ? "Good Morning" : "Good Afternoon";

  const [itemData, setItemData] = useState({
    name: "",
    details: "",
    employees_involved: [],
  });
  const [openDialog, setOpenDialog] = useState(false);
  const handleClickOpen = (id) => {
    // Find the action_item with the matching ID
    const selectedItem = action_items?.find((item) => item._id === id);
    if (selectedItem) {
      // Set the details to itemData
      setItemData({
        name: selectedItem.project_name,
        details: selectedItem.action_details,
        employees_involved: selectedItem.employees_involved,
        deadline: selectedItem.action_deadline,
      });
    }

    // Open the dialog
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid size={12}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            {greeting}
          </Typography>
        </Grid>
        {/* Action Item Card */}
        <Grid size={{ xs: 12, md: 4, lg: 4 }}>
          <Card
            sx={{
              maxWidth: 435,
              backgroundColor: theme.palette.cardColor.main,
            }}
          >
            <Box>
              <CardHeader
                sx={{ pb: 0 }}
                title={
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    <FactCheckIcon sx={{ mr: 1 }} />
                    Action Item
                  </Typography>
                }
              />
              <CardContent
                sx={{
                  height: 400,
                  p: 1,
                  overflowY: "scroll",
                }}
                className="thin-scrollbar"
              >
                <Divider />
                {filteredActionItems?.map((item, index) => (
                  <List
                    key={index}
                    sx={{
                      width: "100%",
                      p: 0,
                    }}
                  >
                    <ListItemButton
                      sx={{ p: 0.5 }}
                      onClick={() => handleClickOpen(item._id)}
                    >
                      <ListItemText>
                        <Typography variant="body2">
                          {item.project_name}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: 1, // Number of lines to show
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {item.action_details}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </List>
                ))}
              </CardContent>
            </Box>
          </Card>
        </Grid>
        {/* Project Card */}
        <Grid size={{ xs: 12, md: 4, lg: 4 }}>
          <Card
            sx={{
              maxWidth: 435,
              backgroundColor: theme.palette.cardColor.main,
            }}
          >
            <Box>
              <CardHeader
                sx={{ pb: 0 }}
                title={
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    <AccountBoxIcon sx={{ mr: 1 }} />
                    Projects
                  </Typography>
                }
              />

              <CardContent
                sx={{
                  height: 400,
                  p: 1,
                  overflowY: "scroll",
                }}
                className="thin-scrollbar"
              >
                <Divider />
                {leads?.map((item, index) => (
                  <List
                    key={index}
                    sx={{
                      width: "100%",
                      p: 0,
                    }}
                  >
                    <ListItemButton
                      sx={{ p: 0.5 }}
                      onClick={() => navigate(`/leads`)}
                    >
                      <ListItemText>
                        <Typography variant="body2">
                          {item.project_name}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            fontStyle: "italic",
                          }}
                        >
                          {item.level_of_priority}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </List>
                ))}
              </CardContent>
            </Box>
          </Card>
        </Grid>

        {/* Events Card */}
        <Grid size={{ xs: 12, md: 4, lg: 4 }}>
          <Card
            sx={{
              maxWidth: 435,
              backgroundColor: theme.palette.cardColor.main,
            }}
          >
            <Box>
              <CardHeader
                sx={{ pb: 0 }}
                title={
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    <DateRangeIcon sx={{ mr: 1 }} />
                    Upcoming Events
                  </Typography>
                }
              />

              <CardContent
                sx={{ height: 400, p: 1, overflowY: "scroll" }}
                className="thin-scrollbar"
              >
                <Divider />
                {upcomingEvents?.map((item, index) => (
                  <List
                    key={index}
                    sx={{
                      width: "100%",
                      p: 0,
                    }}
                  >
                    <ListItemButton
                      sx={{ p: 0.5 }}
                      onClick={() =>
                        navigate(`/event/${item._id}/${item.name}`)
                      }
                    >
                      <ListItemText>
                        <Typography variant="body2">{item.name}</Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            display: "-webkit-box",
                            WebkitLineClamp: 1, // Number of lines to show
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {item.description}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </List>
                ))}
              </CardContent>
            </Box>
          </Card>
        </Grid>

        <Grid size={12} sx={{ mt: 5 }}>
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=Asia%2FManila&bgcolor=%23ffffff&showTz=0&showTitle=0&showPrint=0&showTabs=0&src=aW5mb0BnZW9zcGVjdHJ1bS5jb20ucGg&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=Z2Vvc3BlY3RydW0uY29tLnBoX3Y3YzFpdDU5czdwdGFnM3ExYmY1djZpOWhnQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&src=Y19uYnZhOWlwZmxvOGx0MGUzMmg2b2o2OXU1a0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=ZW4ucGhpbGlwcGluZXMjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23039BE5&color=%2333B679&color=%23AD1457&color=%237986CB&color=%230B8043"
            width="100%"
            height="600"
            frameBorder="0"
          ></iframe>
        </Grid>
      </Grid>

      {/* Dialog for Viewing Action Item */}
      <Dialog open={openDialog} fullWidth maxWidth={"sm"} onClose={handleClose}>
        <DialogTitle sx={{ pb: 1 }}>{"Action Item"}</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={0.5}
            justifyContent={"center"}
            sx={{ mb: 2, mt: 1 }}
          >
            <Grid size={12} display="flex" alignItems="center">
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Project Name{" "}
              </Typography>
            </Grid>
            <Grid size={12} display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Typography variant="body2">{itemData.name} </Typography>
            </Grid>

            <Grid size={12} display="flex" alignItems="center">
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Action Item Details{" "}
              </Typography>
            </Grid>
            <Grid size={12} display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Typography variant="body2">{itemData.details} </Typography>
            </Grid>

            <Grid size={12} display="flex" alignItems="center">
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Deadline{" "}
              </Typography>
            </Grid>
            <Grid size={12} display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Typography variant="body2">
                {moment(itemData.deadline).format("LL")}
              </Typography>
            </Grid>

            <Grid size={12} display="flex" alignItems="center">
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Employee Involved{" "}
              </Typography>
            </Grid>
            <Grid size={12} display="flex" alignItems="center">
              {itemData.employees_involved.map((emp, index) => (
                <Stack
                  key={index}
                  direction="row"
                  spacing={1}
                  sx={{ flexWrap: "nowrap", mr: 1 }}
                >
                  <Chip label={emp.lastname} variant="outlined" />
                </Stack>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Dashboard;
