import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Divider,
  Button,
  InputAdornment,
  Select,
  MenuItem,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Autocomplete,
  FormControlLabel,
  Chip,
  Checkbox,
  Stack,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Toast, confirmDialog } from "../../utils/Alert";
import {
  fetchAllVisitors,
  fetchAttendees,
  addMultipleAttendees,
  sendRegLink,
  resendRegLink,
  sendRegLinkRep,
  resendRepRegLink,
} from "../../services/companyAPI";
import { viewDetails } from "../../services/companyAPI"; //API call
import {
  addAttendee,
  deleteAttendee,
  getAttendeeDetails,
  deleteRep,
  editAttendeeDets,
  addRep,
} from "../../services/eventsAPI"; //API call
import { addleads } from "../../services/leadsAPI";

// Icons
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import EventIcon from "@mui/icons-material/Event";
import StarRateIcon from "@mui/icons-material/StarRate";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MarkunreadIcon from "@mui/icons-material/Markunread";

// Table columns
const columns = [
  {
    id: "vip",
    label: "",
    minWidth: 10,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "status",
    label: "Status",
    minWidth: 110,
    display: {
      xs: "table-cell",
      sm: "table-cell",
    },
  },
  {
    id: "level_of_interest",
    label: "Interest",
    minWidth: 50,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "name",
    label: "Name",
    minWidth: 180,
    display: {
      xs: "table-cell",
      sm: "table-cell",
    },
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "contact",
    label: "Contact",
    minWidth: 130,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "job_title",
    label: "Job Title",
    minWidth: 225,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
];

function EventCompanyDetails({ selectedCompanyData, isMarketing }) {
  // selectedCompanyData (id, name, remaining_seats, alloted_seats)

  const { id, event } = useParams();
  const asteriskStyle = { color: "#c20a0a" };
  const queryClient = useQueryClient();

  // Fetch company details
  const { isLoading: ld, data: companyDets } = useQuery({
    queryKey: ["companyDets"],
    queryFn: () => viewDetails(id, selectedCompanyData.id),
  });

  // TABLE Handle change page
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Fetch all event visistors from the current selected Company
  const { isLoading: loading_, data: comp_visitors } = useQuery({
    queryKey: ["comp_Visitors"],
    queryFn: () => fetchAttendees(id, selectedCompanyData.id),
  });

  // Fetch all event visistors from all events
  const { isLoading, data: visitors } = useQuery({
    queryKey: ["allVisitors"],
    queryFn: () => fetchAllVisitors(),
  });

  // Notification util
  const notification = (icon, message) => {
    Toast.fire({
      icon: icon,
      title: message,
    });
  };

  // State for attendee data
  const [attendeeData, setAttendeeData] = useState({
    name: "",
    vip: false,
    walk_in: false,
    email: "",
    contact_number: "",
    status: "",
    level_of_interest: "",
    job_title: "",
    company_name: "",
    company_email: "",
    company_contact_number: "",
    nature_of_business: "",
    notes: "",
    representatives: [],
  });

  // Update attendeeData once companyDets is fetched
  React.useEffect(() => {
    if (companyDets) {
      setAttendeeData((prevState) => ({
        ...prevState,
        company_name: companyDets.company_name,
        company_email: companyDets.company_email,
        company_contact_number: companyDets.company_contact_number,
        nature_of_business: companyDets.company_nature_of_business,
      }));
    }
  }, [companyDets]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setAttendeeData((prevState) => ({
      ...prevState,
      [name]: name === "vip" ? value === "true" : value, // Convert "true"/"false" to boolean for vip
    }));
  };

  // Adding Attendee mutation
  const addmutation = useMutation({
    mutationFn: (formData) => addAttendee(id, selectedCompanyData.id, formData),
    onSuccess: () => {
      closeDrawer();
      queryClient.invalidateQueries(["attendees", "companies", "companyDets"]);
      notification("success", "Attendee added!");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving the event.");
    },
  });

  const handleSubmitAttendee = () => {
    // Check if any field in formData is empty
    if (
      attendeeData.name === "" ||
      attendeeData.email === "" ||
      attendeeData.level_of_interest === "" ||
      attendeeData.contact_number === "" ||
      attendeeData.status === ""
    ) {
      notification("error", "Fill in required fields.");
      return;
    }
    addmutation.mutate(attendeeData);
  };

  // Add Attendee Drawer
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    console.log(companyDets);
    setOpen(newOpen);
  };

  //closes drawer and clear attendee data
  const closeDrawer = () => {
    setOpen(false);
    setAttendeeData({
      name: "",
      vip: false,
      email: "",
      contact_number: "",
      status: "",
      level_of_interest: "",
      job_title: "",
      company_name: "",
      company_email: "",
      company_contact_number: "",
      nature_of_business: "",
      notes: "",
      representatives: [],
    });
    setAction("");
    setAttendees([]);
    setListAttendee([{ name: "", email: "" }]);
    setSelectPrevious(null);
  };

  const handleRepresentativeChange = (index, field, value) => {
    // Update the specific representative's field
    const updatedReps = [...attendeeData.representatives];
    updatedReps[index][field] = value;
    setAttendeeData((prevState) => ({
      ...prevState,
      representatives: updatedReps,
    }));
  };

  //   Select manual or self regisrtration
  const [action, setAction] = useState(""); // "qr" or "custom"
  const [selectPrevious, setSelectPrevious] = useState(null); // "qr" or "custom"
  const handleActionChange = (type) => {
    setAttendees([]);
    setListAttendee([]);
    setAction(type);
  };
  const handleChangeSelPrev = (event) => {
    const { value } = event.target;
    setSelectPrevious(value);
    setAttendees([]);
    setListAttendee([]);
  };
  // Data for self registration
  const [listAttendee, setListAttendee] = useState([
    // state for attendee data initialized as an array
    { name: "", email: "", contact_number: "" },
  ]);

  // Adding more attendees
  const handleAddAttendee = () => {
    // Add a new attendee object to the list
    setListAttendee((prevState) => [
      ...prevState,
      { name: "", email: "", contact_number: "" }, // New empty attendee
    ]);
  };

  const handleAttendeeChange = (index, field, value) => {
    // Update the specific attendee's field
    const updatedAttendees = [...listAttendee];
    updatedAttendees[index][field] = value;
    setListAttendee(updatedAttendees);
  };

  // Adding Bulk Attendee mutation Self registration thru Link
  const addSelfRegmutation = useMutation({
    mutationFn: (formData) => sendRegLink(id, selectedCompanyData.id, formData),
    onSuccess: () => {
      closeDrawer();
      queryClient.invalidateQueries(["attendees", "companyDets"]);
      notification("success", "Registration Link Sent!");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving.");
    },
  });

  const handleSubmitSelfRegAttendee = () => {
    // Check if any field is empty
    if (listAttendee.length === 0) {
      notification("error", "Please select attendees.");
      return;
    }
    addSelfRegmutation.mutate(listAttendee);
  };

  // Submit Attendee from selected attendees from previous event for self registration
  const SubmitSelfRegAttendeeSelect = () => {
    // Check if any field is empty
    if (listAttendee.length === 0) {
      notification("error", "Please select attendees.");
      return;
    }
    addSelfRegmutation.mutate(listAttendee);
  };

  // Function for selecting employee from previous events
  const [attendees, setAttendees] = useState([]);
  const handleSelectPrevEmp = (event, newValue) => {
    const attdData = newValue.map((attd) => ({
      name: attd.attendee_name,
      email: attd.attendee_email,
      contact_number: attd.attendee_contact_number,
      status: "Pending",
      job_title: attd.attendee_job_title,
      company_name: companyDets.company_name,
      company_email: companyDets.company_email,
      company_contact_number: companyDets.company_contact_number,
      nature_of_business: companyDets.company_nature_of_business,
      level_of_interest: "Casual",
    }));
    setAttendees(attdData);
  };
  // Adding Bulk Attendee mutation
  const addBulkmutation = useMutation({
    mutationFn: (formData) =>
      addMultipleAttendees(id, selectedCompanyData.id, formData),
    onSuccess: () => {
      closeDrawer();
      queryClient.invalidateQueries(["attendees", "companies", "companyDets"]);
      notification("success", "Attendees added!");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving.");
    },
  });

  const handleSubmitBulkAttendee = () => {
    // Check if any field in formData is empty
    if (attendees.length === 0) {
      notification("error", "Please select attendees.");
      return;
    }
    addBulkmutation.mutate(attendees);
  };

  //Editing attendee
  const [openEdit, setOpenEdit] = React.useState(false);
  const [attendeeID, setAttendeeID] = useState(); // selected attendee ID
  const closeDrawerEdit = () => {
    setOpenEdit(false);
    setIsAddrep(false);
    setAttendeeData({
      name: "",
      vip: false,
      email: "",
      contact_number: "",
      status: "",
      level_of_interest: "",
      job_title: "",
      company_name: "",
      company_email: "",
      company_contact_number: "",
      nature_of_business: "",
      notes: "",
      representatives: [],
    });
  };

  const editAttendee = async (attendee_ID) => {
    setOpenEdit(true);
    setAttendeeID(attendee_ID);
    // Conditionally choose the correct function
    const selected_attendee = await getAttendeeDetails(
      attendee_ID,
      id,
      companyDets.company_id
    );
    // console.log(selected_attendee);
    setAttendeeData(selected_attendee);
  };

  // Edit Attendee
  const editAttendeemutation = useMutation({
    mutationFn: ({ attendee_ID }) =>
      editAttendeeDets(attendee_ID, id, companyDets.company_id, attendeeData),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["attendees", "comp_Visitors"]);
      notification("success", "Attendee updated!");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving the event.");
    },
  });

  const handleEditAttendee = (attendee_ID) => {
    // Check if any field in formData is empty
    if (
      attendeeData.name === "" ||
      attendeeData.email === "" ||
      attendeeData.level_of_interest === "" ||
      attendeeData.contact_number === ""
      // attendeeData.status === ""
    ) {
      notification("error", "Fill in required fields.");
      return;
    }
    editAttendeemutation.mutate({ attendee_ID });
  };

  // Delete Attendee______________________
  const deleteAttendeeMutation = useMutation({
    mutationFn: () => deleteAttendee(attendeeID, id, companyDets.company_id),

    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["attendees", "comp_Visitors"]);
      notification("success", "Attendee successfully deleted");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while deleting the attendee.");
    },
  });

  const handleDeleteAttendee = async () => {
    const userConfirmed = await confirmDialog({
      title: "Delete attendee?",
      text: "This action cannot be undone.",
    });
    if (userConfirmed) {
      deleteAttendeeMutation.mutate();
    }
  };

  // Delete Representative______________________
  const deleteRepMutation = useMutation({
    mutationFn: ({ visitorID, repID }) =>
      deleteRep(id, visitorID, repID, companyDets.company_id),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["attendees"]);
      notification("success", "Representative successfully deleted");
    },
  });

  const handleDeleteRep = async (visitorID, repID) => {
    const userConfirmed = await confirmDialog({
      title: "Delete representative?",
      text: "This action cannot be undone.",
    });
    if (userConfirmed) {
      deleteRepMutation.mutate({ visitorID, repID });
    }
  };

  // Resending registration link section
  const [resendLink, setResendLink] = React.useState(false);
  const [resendLinkRep, setResendLinkRep] = React.useState(false);
  const [selectedAttendee, setSelectedAttendee] = React.useState([]);

  const selectAttenddeList = (id_, checked) => {
    setSelectedAttendee((prev) => {
      if (checked) {
        // Add new selection (if not already in the list)
        return prev.includes(id_) ? prev : [...prev, id_];
      } else {
        // Remove attendee from the list if unchecked
        return prev.filter((attendeeId) => attendeeId !== id_);
      }
    });
  };

  const handleresendLink = () => {
    if (selectedAttendee.length === 0) {
      notification("error", "Please select an attendee");
    } else {
      resendLinkemutation.mutate();
    }
  };

  const resendLinkemutation = useMutation({
    mutationFn: () =>
      resendRegLink(id, companyDets.company_id, selectedAttendee),
    onSuccess: () => {
      setResendLink(false);
      setSelectedAttendee([]);
      queryClient.invalidateQueries(["attendees", "comp_Visitors"]);
      notification("success", "Link sent successfully!");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving the event.");
    },
  });
  // Resend email to representatives
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRepAttendee, setSelectedRepAttendee] = React.useState([]);

  const handleOpenModalResend = () => {
    setOpenDialog(true);
    setResendLinkRep(!resendLinkRep);
  };

  const handleCloseModalResend = () => {
    setSelectedRepAttendee([]);
    setOpenDialog(false);
  };

  const selectRepAttenddeList = (id_, checked) => {
    setSelectedRepAttendee((prev) => {
      if (checked) {
        // Add new selection (if not already in the list)
        return prev.includes(id_) ? prev : [...prev, id_];
      } else {
        // Remove attendee from the list if unchecked
        return prev.filter((attendeeId) => attendeeId !== id_);
      }
    });
  };

  const handleresendRepLink = () => {
    // console.log(selectedRepAttendee);
    if (selectedRepAttendee.length === 0) {
      notification("error", "Please select an attendee");
    } else {
      resendRepLinkemutation.mutate();
    }
  };

  const resendRepLinkemutation = useMutation({
    mutationFn: () =>
      resendRepRegLink(id, companyDets.company_id, selectedRepAttendee),
    onSuccess: () => {
      setResendLink(false);
      handleCloseModalResend();
      queryClient.invalidateQueries(["attendees", "comp_Visitors"]);
      notification("success", "Link sent successfully!");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving the event.");
    },
  });

  // Adding more representative (ADD AND INVITE; SEND REGISTRATION)
  const [rep_action, setRep_action] = useState(""); // "qr" or "custom"
  const handleRepActionChange = (type) => {
    // console.log(type);
    setRep_action(type);
  };

  // Adding more representative (ADD ONLY)
  const handleAddRepresentative = () => {
    setRepresentatives((prevState) => [
      ...prevState,
      { name: "", email: "", contact_number: "", company_name: "", role: "" },
    ]);
  };

  const handleAddRepresentativeChange = (index, field, value) => {
    // Create a shallow copy of the representatives array
    const updatedReps = [...representatives];

    // Update the specific field of the representative at the given index
    updatedReps[index] = { ...updatedReps[index], [field]: value };

    // Update state with the modified representatives array
    setRepresentatives(updatedReps);
  };

  const handleAddRepDrawer = () => {
    setIsAddrep(true);
  };

  const [isAddrep, setIsAddrep] = React.useState(false);
  const [representatives, setRepresentatives] = React.useState([
    { name: "", email: "", contact_number: "", role: "" },
  ]);

  const handleAddRep = async (visitorID) => {
    // console.log(representatives);
    if (
      representatives.name === "" ||
      representatives.email === "" ||
      representatives.role === "" ||
      representatives.contact_number === ""
    ) {
      notification("info", "Please input required field");
    } else {
      const userConfirmed = await confirmDialog({
        title: "Add representative?",
        text: "Representaive/s will be added on the selected attendee.",
      });
      if (userConfirmed) {
        AddRepMutation.mutate({ visitorID });
      }
    }
  };

  const AddRepMutation = useMutation({
    mutationFn: ({ visitorID }) =>
      rep_action === "Add"
        ? addRep(id, companyDets.company_id, visitorID, representatives)
        : sendRegLinkRep(
            id,
            companyDets.company_id,
            visitorID,
            representatives
          ),
    onSuccess: () => {
      closeDrawerEdit();
      setRep_action("");
      setIsAddrep(false);
      setRepresentatives([
        {
          name: "",
          email: "",
          contact_number: "",
          company_name: "",
          role: "",
        },
      ]);
      queryClient.invalidateQueries(["attendees"]);
      notification("success", "Representative successfully added");
    },
  });

  // Copy to leads
  const [toLeads, setToLeads] = useState([]);
  const copytoLeads = async () => {
    setToLeads({
      project_name: "Event:" + event,
      company_name: attendeeData.company_name,
      implementing_agency: "",
      company_email: attendeeData.company_email,
      company_contact_number: attendeeData.company_contact_number,
      estimated_value: "10",
      nature_of_business: attendeeData.nature_of_business,
      interaction_logs: [],
      action_items: [],
      status: "Outbound",
      level_of_priority: "",
      product_interested_in: [],
      source: event,
      contact_person: [],
      champion_details: [
        {
          name: attendeeData.visitor_name,
          email: attendeeData.email,
          phone_number: attendeeData.contact_number,
          job_title: attendeeData.job_title,
        },
      ],
    });

    const userConfirmed = await confirmDialog({
      title: "Copy attendee?",
      text: "Details will be copy to Leads",
    });
    if (userConfirmed) {
      copyLeadmutation.mutate();
    }
  };

  // Copy attendeed to Leads
  const copyLeadmutation = useMutation({
    mutationFn: () => addleads(toLeads),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["leads"]);
      notification("success", "Copied Successfully !");
    },
    onError: (error) => {
      console.log(error);
      notification("error", error.response.data.error);
    },
  });

  if (ld || loading_ || isLoading) return <div>Loading...</div>; //loading state

  return (
    <>
      {/* Main */}
      <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ mb: 2 }} variant="h5">
          {selectedCompanyData.name}
          <Chip
            sx={{ ml: 1 }}
            size="small"
            color="default"
            label={
              companyDets.company_remaining_seats +
              "/" +
              companyDets.company_alloted_seats +
              " seats"
            }
          />
          {isMarketing && (
            <>
              <IconButton
                title="Add Attendee"
                size="large"
                onClick={toggleDrawer(true)}
                sx={{ p: 0, ml: 3 }}
              >
                <GroupAddIcon fontSize="inherit" />
              </IconButton>
              <Typography
                variant="caption"
                gutterBottom
                component="button"
                onClick={() => setResendLink(!resendLink)}
                sx={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  padding: 0,
                  fontSize: 12,
                  ml: 2,
                  transition: "color 0.3s ease",
                  "&:hover": {
                    color: "#0f68a3",
                  },
                }}
              >
                Resend Link
              </Typography>

              <Typography
                variant="caption"
                gutterBottom
                component="button"
                onClick={() => handleOpenModalResend()}
                sx={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  padding: 0,
                  fontSize: 12,
                  ml: 2,
                  transition: "color 0.3s ease",
                  "&:hover": {
                    color: "#0f68a3",
                  },
                }}
              >
                <Collapse in={resendLink} timeout={"auto"} unmountOnExit>
                  Representatives
                </Collapse>
              </Typography>
            </>
          )}
        </Typography>

        <Grid size={12} sx={{ mb: 2 }}>
          <TableContainer
            sx={{
              width: "100%",
              // overflow: "hidden",
            }}
            className="thin-scrollbar"
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {/* {resendLink && ( */}
                  <TableCell
                    align={"center"}
                    style={{ maxWidth: 1 }}
                  ></TableCell>
                  {/* )} */}
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      sx={{
                        pl: "10px",
                        pr: "10px",
                        display: column.display || "table-cell",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading_ ? (
                  // Display a loading indicator
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : (
                  comp_visitors
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow
                        key={index}
                        onClick={() => editAttendee(row.attendee_id)}
                        className="table-row"
                      >
                        <TableCell align="left" sx={{ padding: "5px" }}>
                          <Collapse in={resendLink} timeout={300} unmountOnExit>
                            {resendLink && row.self_registered && (
                              <Checkbox
                                checked={selectedAttendee.includes(
                                  row.attendee_id
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  selectAttenddeList(
                                    row.attendee_id,
                                    e.target.checked
                                  );
                                }}
                              />
                            )}
                          </Collapse>
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            padding: "5px",
                            display: {
                              xs: "none", // Hide on extra-small screens
                              sm: "table-cell", // Show on small screens and larger
                            },
                          }}
                        >
                          {row.attendee_vip ? (
                            <StarRateIcon fontSize="small" />
                          ) : (
                            ""
                          )}
                        </TableCell>
                        {/*Hide status cell if special event is false  */}
                        <TableCell align="left" sx={{ pl: "5px", pr: "5px" }}>
                          <Stack direction="row">
                            <Chip
                              size="small"
                              color={
                                row.attendee_status === "Confirmed"
                                  ? "primary"
                                  : row.attendee_status === "No-show"
                                    ? "secondary"
                                    : row.attendee_status === "Attended"
                                      ? "success"
                                      : row.attendee_status === "Rejected"
                                        ? "error"
                                        : "default" // or any other default color
                              }
                              label={row.attendee_status}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            pl: "5px",
                            pr: "5px",
                            display: {
                              xs: "none", // Hide on extra-small screens
                              sm: "table-cell", // Show on small screens and larger
                            },
                          }}
                        >
                          {row.attendee_level_of_interest}
                        </TableCell>
                        <TableCell align="left" sx={{ pl: "10px", pr: "10px" }}>
                          {row.attendee_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            pl: "10px",
                            pr: "10px",
                            display: {
                              xs: "none", // Hide on extra-small screens
                              sm: "table-cell", // Show on small screens and larger
                            },
                          }}
                        >
                          {row.attendee_email}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            pl: "10px",
                            pr: "10px",
                            display: {
                              xs: "none", // Hide on extra-small screens
                              sm: "table-cell", // Show on small screens and larger
                            },
                          }}
                        >
                          {row.attendee_contact_number}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            pl: "10px",
                            pr: "10px",
                            display: {
                              xs: "none", // Hide on extra-small screens
                              sm: "table-cell", // Show on small screens and larger
                            },
                          }}
                        >
                          {row.attendee_job_title}
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={comp_visitors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Collapse in={resendLink} timeout="auto" unmountOnExit>
            {resendLink && (
              <Button
                variant="contained"
                startIcon={<MarkunreadIcon />}
                onClick={handleresendLink}
              >
                resend Registration Link
              </Button>
            )}
          </Collapse>
        </Grid>
      </Box>

      {/* Drawer for Adding an Attendee */}
      <Drawer open={open} onClose={() => closeDrawer()}>
        <Box
          className="thin-scrollbar"
          sx={{ width: { xs: 360, lg: 480, xl: 550 }, mt: 8, p: 2 }}
          role="presentation"
        >
          <>
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid size={12} display="flex" alignItems="center">
                <EventIcon />
                <Typography variant="body" sx={{ ml: 1 }}>
                  Add Attendee
                </Typography>
              </Grid>
              <Grid size={12}>
                <Divider />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0.5} columnSpacing={1} sx={{ mt: 1 }}>
              <Grid size={6} display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={action === "manual"}
                      onChange={() => handleActionChange("manual")}
                    />
                  }
                  label="Manual"
                />
              </Grid>
              <Grid size={6} display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={action === "self-registration"}
                      onChange={() => handleActionChange("self-registration")}
                    />
                  }
                  label="Self-registration"
                />
              </Grid>
              <Grid size={12}>
                <Typography variant="body2">
                  Select Attendee from previous event?
                </Typography>
              </Grid>
              <Grid size={12}>
                <Select
                  label="se"
                  size="small"
                  fullWidth
                  name="status"
                  value={selectPrevious ?? ""}
                  onChange={handleChangeSelPrev}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </Grid>
              {/* Manual adding and selecting attendees from previous event */}
              {action === "manual" && selectPrevious == true ? (
                <>
                  <Grid size={12}>
                    <Typography variant="body2">Select Attendee</Typography>
                  </Grid>
                  <Grid size={12}>
                    <Autocomplete
                      options={visitors}
                      getOptionLabel={(option) => option.attendee_name || ""}
                      filterSelectedOptions
                      multiple
                      onChange={handleSelectPrevEmp}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </Grid>
                  <Grid
                    size={12}
                    container
                    justifyContent="flex-end"
                    sx={{ mt: 3 }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={closeDrawer}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ ml: 1 }}
                      onClick={handleSubmitBulkAttendee}
                    >
                      Save
                    </Button>
                  </Grid>
                </>
              ) : // Manual adding of attendee
              action === "manual" && selectPrevious == false ? (
                <>
                  <Grid size={10}>
                    <Typography variant="body2">
                      Name <span style={asteriskStyle}>*</span>
                    </Typography>
                  </Grid>
                  <Grid size={2}>
                    <Typography variant="body2">VIP?</Typography>
                  </Grid>
                  <Grid size={9}>
                    <TextField
                      fullWidth
                      size="small"
                      name="name"
                      value={attendeeData.name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid size={3}>
                    <Select
                      size="small"
                      fullWidth
                      name="vip"
                      value={String(attendeeData.vip)} // Convert boolean to string for the Select component
                      onChange={handleChange}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </Grid>
                  <Grid size={6}>
                    <Typography variant="body2">
                      Status <span style={asteriskStyle}>*</span>
                    </Typography>
                  </Grid>

                  <Grid size={6}>
                    <Typography variant="body2">
                      Level of Interest <span style={asteriskStyle}>*</span>
                    </Typography>
                  </Grid>

                  <Grid size={6}>
                    <Select
                      size="small"
                      fullWidth
                      name="status"
                      value={attendeeData.status}
                      onChange={handleChange}
                    >
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Confirmed">Confirmed</MenuItem>
                      <MenuItem value="Rejected">Rejected</MenuItem>
                      <MenuItem value="Attended">Attended</MenuItem>
                      <MenuItem value="No-show">No-show</MenuItem>
                    </Select>
                  </Grid>

                  <Grid size={6}>
                    <Select
                      size="small"
                      fullWidth
                      name="level_of_interest"
                      value={attendeeData.level_of_interest}
                      onChange={handleChange}
                    >
                      <MenuItem value="Potential">Potential</MenuItem>
                      <MenuItem value="Casual">Casual</MenuItem>
                    </Select>
                  </Grid>

                  <Grid size={12}>
                    <Typography variant="body2">Role / Position</Typography>
                  </Grid>
                  <Grid size={12}>
                    <TextField
                      fullWidth
                      size="small"
                      name="job_title"
                      value={attendeeData.job_title}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid size={6}>
                    <Typography variant="body2">
                      Email <span style={asteriskStyle}>*</span>
                    </Typography>
                  </Grid>
                  <Grid size={6}>
                    <Typography variant="body2">
                      Phone <span style={asteriskStyle}>*</span>
                    </Typography>
                  </Grid>
                  <Grid size={6}>
                    <TextField
                      fullWidth
                      size="small"
                      name="email"
                      value={attendeeData.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid size={6}>
                    <TextField
                      fullWidth
                      size="small"
                      name="contact_number"
                      type="number"
                      value={attendeeData.contact_number}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid size={12}>
                    <Typography variant="body2">Note</Typography>
                  </Grid>
                  <Grid size={12}>
                    <TextField
                      fullWidth
                      size="small"
                      multiline
                      rows={3}
                      name="notes"
                      value={attendeeData.notes}
                      onChange={handleChange}
                    />
                  </Grid>

                  {/* {attendeeData?.representatives?.map((rep, index) => (
                    <>
                      <Grid item size={12} sx={{ mt: 2, mb: 2 }}>
                        <Divider />
                      </Grid>
                      <Grid item size={12}>
                        <Typography variant="body2">
                          Representative Name
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          value={rep.name}
                          onChange={(e) =>
                            handleRepresentativeChange(
                              index,
                              "name",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item size={12}>
                        <Typography variant="body2">Role / Position</Typography>
                        <TextField
                          fullWidth
                          size="small"
                          value={rep.role}
                          onChange={(e) =>
                            handleRepresentativeChange(
                              index,
                              "role",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item size={6}>
                        <Typography variant="body2">Email</Typography>
                        <TextField
                          fullWidth
                          size="small"
                          value={rep.email}
                          onChange={(e) =>
                            handleRepresentativeChange(
                              index,
                              "email",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item size={6}>
                        <Typography variant="body2">Phone Number</Typography>
                        <TextField
                          fullWidth
                          size="small"
                          value={rep.contact_number}
                          onChange={(e) =>
                            handleRepresentativeChange(
                              index,
                              "contact_number",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </>
                  ))} */}
                  {/* <Grid size={2}>
                    <IconButton
                      title="Add Representative"
                      onClick={handleAddRepresentative}
                    >
                      <PersonAddAltIcon />
                    </IconButton>
                  </Grid>
                  <Grid size={10} alignContent={"center"}>
                    <Typography variant="body2">
                      Click to add Representative/s.
                    </Typography>
                  </Grid> */}

                  <Grid
                    size={12}
                    container
                    justifyContent="flex-end"
                    sx={{ mt: 3 }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={closeDrawer}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ ml: 1 }}
                      onClick={handleSubmitAttendee}
                    >
                      Save
                    </Button>
                  </Grid>
                </>
              ) : //  Attendee Self registration; input name and email. Link will be sent to the attendee
              action === "self-registration" && selectPrevious == false ? (
                <>
                  {listAttendee?.map((attendee, index) => (
                    <>
                      <Grid item size={12} sx={{ mt: 2, mb: 2 }}>
                        <Divider />
                      </Grid>
                      <Grid item size={12}>
                        <Typography variant="body2">
                          Attendee Name {index + 1}
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          value={attendee.name}
                          onChange={(e) =>
                            handleAttendeeChange(index, "name", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item size={12}>
                        <Typography variant="body2">Email</Typography>
                        <TextField
                          fullWidth
                          size="small"
                          value={attendee.email}
                          onChange={(e) =>
                            handleAttendeeChange(index, "email", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item size={12}>
                        <Typography variant="body2">Contact Number</Typography>
                        <TextField
                          fullWidth
                          size="small"
                          value={attendee.contact_number}
                          onChange={(e) =>
                            handleAttendeeChange(
                              index,
                              "contact_number",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </>
                  ))}

                  <Grid size={2}>
                    <IconButton
                      title="Add Representative"
                      onClick={handleAddAttendee}
                    >
                      <PersonAddAltIcon />
                    </IconButton>
                  </Grid>
                  <Grid size={10} alignContent={"center"}>
                    <Typography variant="body2">
                      Click to add more Attendee/s.
                    </Typography>
                  </Grid>
                  <Grid size={12}>
                    <Button
                      variant="contained"
                      onClick={handleSubmitSelfRegAttendee}
                    >
                      Submit
                    </Button>
                  </Grid>
                </>
              ) : // Attendee self registration; Select attendees from previous events (Link will be sent to the attendee)
              action === "self-registration" && selectPrevious == true ? (
                <>
                  <Grid size={12}>
                    <Typography variant="body2">Select Attendees</Typography>
                  </Grid>
                  <Grid size={12}>
                    <Autocomplete
                      multiple
                      options={visitors}
                      getOptionLabel={(option) => option.attendee_name}
                      filterSelectedOptions
                      onChange={(event, selectedOptions) => {
                        setListAttendee(
                          selectedOptions.map((option) => ({
                            name: option.attendee_name,
                            email: option.attendee_email,
                            contact_number: option.attendee_contact_number,
                          }))
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Attendee"
                          placeholder="Name"
                        />
                      )}
                    />
                  </Grid>

                  <Grid size={12}>
                    <Button
                      variant="contained"
                      onClick={SubmitSelfRegAttendeeSelect}
                    >
                      Submit
                    </Button>
                  </Grid>
                </>
              ) : (
                "Select Action"
              )}
            </Grid>
          </>
        </Box>
      </Drawer>

      {/* Drawer for Editing an Attendee */}
      <Drawer open={openEdit} onClose={() => closeDrawerEdit()}>
        {!isAddrep ? (
          <Box
            className="thin-scrollbar"
            sx={{ width: { xs: 360, lg: 480, xl: 550 }, mt: 8, p: 2 }}
            role="presentation"
          >
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid size={12} display="flex" alignItems="center">
                <EventIcon />
                <Typography variant="body" sx={{ ml: 1 }}>
                  Edit Attendee
                  {attendeeData.self_registered && (
                    <Chip label="Self-registered" size="small" sx={{ ml: 1 }} />
                  )}
                </Typography>
              </Grid>
              <Grid size={12}>
                <Divider />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0.5} columnSpacing={1} sx={{ mt: 1 }}>
              <Grid size={10}>
                <Typography variant="body2">
                  Name <span style={asteriskStyle}>*</span>
                </Typography>
              </Grid>
              <Grid size={2}>
                <Typography variant="body2">VIP?</Typography>
              </Grid>
              <Grid size={9}>
                <TextField
                  fullWidth
                  size="small"
                  disabled={!isMarketing}
                  name="visitor_name"
                  value={attendeeData.visitor_name || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={3}>
                <Select
                  size="small"
                  disabled={!isMarketing}
                  fullWidth
                  name="vip"
                  value={
                    attendeeData.vip !== undefined
                      ? String(attendeeData.vip)
                      : ""
                  } // Convert boolean to string for the Select component
                  onChange={handleChange}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </Grid>
              <Grid size={6}>
                <Typography variant="body2">
                  Status <span style={asteriskStyle}>*</span>
                </Typography>
              </Grid>
              <Grid size={6}>
                <Typography variant="body2">
                  Level of Interest <span style={asteriskStyle}>*</span>
                </Typography>
              </Grid>

              <Grid size={6}>
                <Select
                  size="small"
                  disabled={!isMarketing}
                  fullWidth
                  name="status"
                  value={attendeeData.status || ""}
                  onChange={handleChange}
                >
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Confirmed">Confirmed</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                  <MenuItem value="Attended">Attended</MenuItem>
                  <MenuItem value="No-show">No-show</MenuItem>
                </Select>
              </Grid>

              <Grid size={6}>
                <Select
                  size="small"
                  disabled={!isMarketing}
                  fullWidth
                  name="level_of_interest"
                  value={attendeeData.level_of_interest || ""}
                  onChange={handleChange}
                >
                  <MenuItem value="Potential">Potential</MenuItem>
                  <MenuItem value="Casual">Casual</MenuItem>
                </Select>
              </Grid>

              <Grid size={12}>
                <Typography variant="body2">Role / Position</Typography>
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  size="small"
                  disabled={!isMarketing}
                  name="job_title"
                  value={attendeeData.job_title || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={6}>
                <Typography variant="body2">
                  Email <span style={asteriskStyle}>*</span>
                </Typography>
              </Grid>
              <Grid size={6}>
                <Typography variant="body2">
                  Phone <span style={asteriskStyle}>*</span>
                </Typography>
              </Grid>
              <Grid size={6}>
                <TextField
                  fullWidth
                  size="small"
                  disabled={!isMarketing}
                  name="email"
                  value={attendeeData.email || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={6}>
                <TextField
                  fullWidth
                  size="small"
                  disabled={!isMarketing}
                  name="contact_number"
                  type="number"
                  value={attendeeData.contact_number || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={12}>
                <Typography variant="body2">Company / Organization</Typography>
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  size="small"
                  name="company_name"
                  value={attendeeData.company_name || ""}
                  onChange={handleChange}
                  disabled
                />
              </Grid>
              <Grid size={12}>
                <Typography variant="body2">Industry</Typography>
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  size="small"
                  disabled
                  name="nature_of_business"
                  value={attendeeData.nature_of_business || ""}
                />
              </Grid>
              <Grid size={6}>
                <Typography variant="body2">Company / Org. Email</Typography>
              </Grid>
              <Grid size={6}>
                <Typography variant="body2">Company / Org. Phone</Typography>
              </Grid>
              <Grid size={6}>
                <TextField
                  fullWidth
                  size="small"
                  disabled
                  name="company_email"
                  value={attendeeData.company_email || ""}
                />
              </Grid>
              <Grid size={6}>
                <TextField
                  fullWidth
                  size="small"
                  name="company_contact_number"
                  type="number"
                  value={attendeeData.company_contact_number || ""}
                  disabled
                />
              </Grid>
              <Grid size={12}>
                <Typography variant="body2">Note</Typography>
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  size="small"
                  disabled={!isMarketing}
                  multiline
                  rows={3}
                  name="notes"
                  value={attendeeData.notes || ""}
                  onChange={handleChange}
                />
              </Grid>

              {attendeeData.representatives.map((rep, index) => (
                <React.Fragment key={index}>
                  <Grid size={12} sx={{ mt: 2, mb: 2 }}>
                    <Divider />
                  </Grid>
                  <Grid size={12}>
                    <Typography variant="body2">
                      Representative Name {index + 1}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      disabled={!isMarketing}
                      value={rep.name || ""}
                      onChange={(e) =>
                        handleRepresentativeChange(
                          index,
                          "name",
                          e.target.value
                        )
                      }
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                disabled={!isMarketing}
                                title="Delete Representative"
                                onClick={() =>
                                  handleDeleteRep(
                                    attendeeData.attendee_id,
                                    rep._id,
                                    attendeeData.company_id
                                  )
                                }
                              >
                                <PersonRemoveIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </Grid>
                  <Grid size={12}>
                    <Typography variant="body2">Role / Position</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      disabled={!isMarketing}
                      value={rep.role || ""}
                      onChange={(e) =>
                        handleRepresentativeChange(
                          index,
                          "role",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid size={6}>
                    <Typography variant="body2">Email</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      disabled={!isMarketing}
                      value={rep.email || ""}
                      onChange={(e) =>
                        handleRepresentativeChange(
                          index,
                          "email",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid size={6}>
                    <Typography variant="body2">Phone Number</Typography>
                    <TextField
                      fullWidth
                      disabled={!isMarketing}
                      size="small"
                      value={rep.contact_number || ""}
                      onChange={(e) =>
                        handleRepresentativeChange(
                          index,
                          "contact_number",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                </React.Fragment>
              ))}
              <Grid size={12}>
                <Divider />
              </Grid>
              {isMarketing && (
                <>
                  <Grid size={2}>
                    <IconButton
                      title="Add Representative"
                      onClick={handleAddRepDrawer}
                    >
                      <PersonAddAltIcon />
                    </IconButton>
                  </Grid>
                  <Grid size={10} alignContent={"center"}>
                    <Typography variant="body2">
                      Click to add Representative/s.
                    </Typography>
                  </Grid>

                  <Grid
                    size={12}
                    container
                    justifyContent="flex-end"
                    sx={{ mt: 3 }}
                  >
                    <IconButton
                      title="Delete"
                      onClick={() => handleDeleteAttendee()}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                    <Button
                      variant="contained"
                      size="small"
                      color="outboundColor"
                      onClick={copytoLeads}
                      sx={{ mr: 0.5 }}
                    >
                      Copy to Leads
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      sx={{ mr: 0.5 }}
                      onClick={closeDrawerEdit}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ mr: 0.5 }}
                      onClick={() =>
                        handleEditAttendee(attendeeData.attendee_id)
                      }
                    >
                      Update
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        ) : (
          <>
            {" "}
            {/* Drawer to add a new representative */}
            <Box
              className="thin-scrollbar"
              sx={{ width: { xs: 360, lg: 480, xl: 550 }, mt: 8, p: 2 }}
              role="presentation"
            >
              <Grid container rowSpacing={0.5} columnSpacing={1} sx={{ mt: 1 }}>
                <Grid size={6} display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rep_action === "Add"}
                        onChange={() => handleRepActionChange("Add")}
                      />
                    }
                    label="Add"
                  />
                </Grid>
                <Grid size={6} display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rep_action === "Add & Invite"}
                        onChange={() => handleRepActionChange("Add & Invite")}
                      />
                    }
                    label="Add & Invite"
                  />
                </Grid>

                {/* Add representative only */}
                {rep_action === "Add" ? (
                  <>
                    {representatives.map((rep, index) => (
                      <React.Fragment key={index}>
                        <Grid size={12}>
                          <Typography variant="body2">
                            Representative Name {index + 1}
                          </Typography>
                          <TextField
                            fullWidth
                            size="small"
                            disabled={!isMarketing}
                            value={rep.name || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={12}>
                          <Typography variant="body2">
                            Role / Position
                          </Typography>
                          <TextField
                            fullWidth
                            size="small"
                            disabled={!isMarketing}
                            value={rep.role || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "role",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={6}>
                          <Typography variant="body2">Email</Typography>
                          <TextField
                            fullWidth
                            size="small"
                            disabled={!isMarketing}
                            value={rep.email || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "email",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={6}>
                          <Typography variant="body2">Phone Number</Typography>
                          <TextField
                            fullWidth
                            disabled={!isMarketing}
                            size="small"
                            value={rep.contact_number || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "contact_number",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={2}>
                          <IconButton
                            title="Add Representative"
                            onClick={handleAddRepresentative}
                          >
                            <PersonAddAltIcon />
                          </IconButton>
                        </Grid>
                        <Grid size={10} alignContent="center">
                          <Typography variant="body2">
                            Click to add Representative/s.
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </>
                ) : rep_action === "Add & Invite" ? (
                  <>
                    {representatives.map((rep, index) => (
                      <React.Fragment key={index}>
                        <Grid size={12}>
                          <Typography variant="body2">
                            Representative Name {index + 1}
                          </Typography>
                          <TextField
                            fullWidth
                            size="small"
                            disabled={!isMarketing}
                            value={rep.name || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={12}>
                          <Typography variant="body2">
                            Role / Position
                          </Typography>
                          <TextField
                            fullWidth
                            size="small"
                            disabled={!isMarketing}
                            value={rep.role || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "role",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={6}>
                          <Typography variant="body2">Email</Typography>
                          <TextField
                            fullWidth
                            size="small"
                            disabled={!isMarketing}
                            value={rep.email || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "email",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={6}>
                          <Typography variant="body2">Phone Number</Typography>
                          <TextField
                            fullWidth
                            disabled={!isMarketing}
                            size="small"
                            value={rep.contact_number || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "contact_number",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={2}>
                          <IconButton
                            title="Add Representative"
                            onClick={handleAddRepresentative}
                          >
                            <PersonAddAltIcon />
                          </IconButton>
                        </Grid>
                        <Grid size={10} alignContent="center">
                          <Typography variant="body2">
                            Click to add Representative/s.
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    <Typography>Select action</Typography>
                  </>
                )}

                <Grid
                  size={12}
                  container
                  justifyContent="flex-end"
                  sx={{ mt: 3 }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    sx={{ mr: 0.5 }}
                    onClick={() => setIsAddrep(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ mr: 0.5 }}
                    onClick={() => handleAddRep(attendeeData.attendee_id)}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Drawer>

      {/* Dialog for Resending Representative Link */}
      <Dialog
        open={openDialog}
        onClose={handleCloseModalResend}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogContent>
          <Grid size={12} sx={{ mb: 2 }}>
            <TableContainer
              sx={{
                width: "100%",
                backgroundColor: "white",
                // overflow: "hidden",
              }}
              className="thin-scrollbar"
            >
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align={"center"}></TableCell>
                    <TableCell align={"left"}>Name</TableCell>
                    <TableCell align={"left"}>Role</TableCell>
                    <TableCell align={"left"}>Email</TableCell>
                    <TableCell align={"left"}>Contact Number</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {comp_visitors.map((visitor, visitorIndex) =>
                    visitor.attendee_representatives
                      ?.filter((rep) => rep.self_registered) // Filter only self-registered representatives
                      .map((rep, repIndex) => (
                        <TableRow
                          key={`${visitorIndex}-${repIndex}`}
                          className="table-row"
                        >
                          <TableCell
                            align="center"
                            sx={{ pl: "5px", pr: "5px" }}
                          >
                            <Checkbox
                              checked={selectedRepAttendee.includes(rep._id)}
                              onClick={(e) => {
                                selectRepAttenddeList(
                                  rep._id,
                                  e.target.checked
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell align="left" sx={{ pl: "5px", pr: "5px" }}>
                            {rep.name || ""}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ pl: "10px", pr: "10px" }}
                          >
                            {rep.role || ""}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ pl: "10px", pr: "10px" }}
                          >
                            {rep.email || ""}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ pl: "10px", pr: "10px" }}
                          >
                            {rep.contact_number || ""}
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalResend}>Cancel</Button>
          <Button onClick={handleresendRepLink} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EventCompanyDetails;
