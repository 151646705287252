import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import { Typography } from "@mui/material";

function Thankyoupage() {
  return (
    <>
      <Box
        component="section"
        sx={{
          m: { xs: 2, md: 5, lg: 20, xl: 25 },
          mt: { xs: 2, md: 4, lg: 13, xl: 15 },
          mb: { xs: 2, md: 2, lg: 5, xl: 5 },
          boxShadow: 3,
          borderRadius: 5,
          backgroundColor: "#f5f5f5",
          color: "black",
        }}
      >
        <Grid
          container
          rowSpacing={1}
          columnSpacing={2}
          sx={{
            p: 2,
            pl: { xs: 1, md: 1, lg: 3, xl: 3 },
            pr: { xs: 1, md: 1, lg: 3, xl: 3 },
          }}
        >
          <Grid size={12} sx={{ textAlign: "flex-start", pb: 2 }}>
            <Box
              component="section"
              sx={{
                p: 2,
                border: 1,
                borderColor: "#d7d7d7",
                borderRadius: 1,
                backgroundColor: "#013976",
              }}
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={1}
                sx={{ p: 1, pl: 2, pr: 2 }}
              >
                <Grid size={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
                  <Typography variant="h4" sx={{ color: "white" }}>
                    Registration Form
                  </Typography>
                </Grid>
                <Grid
                  item
                  size={{ xs: 12, md: 3, lg: 3, xl: 3 }}
                  container
                  alignItems="center"
                >
                  <Box
                    component="img"
                    src={`${process.env.REACT_APP_BACKEND_DOMAIN}/Event-Imgs/logo-white-gms.png`}
                    sx={{
                      width: "100%",
                      maxWidth: 300,
                      height: "auto",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            size={{ xs: 12, md: 12, lg: 6, xl: 6 }}
            sx={{ textAlign: "center" }}
          >
            <Box
              component="img"
              src={`${process.env.REACT_APP_BACKEND_DOMAIN}/Event-Imgs/defaultEvent_wallpaper.jpg`}
              sx={{
                width: "100%",
                maxWidth: 500,
                height: "auto",
                borderRadius: 2,
              }}
            />
          </Grid>
          <Grid
            size={{ xs: 12, md: 12, lg: 6, xl: 6 }}
            sx={{
              textAlign: "flex-start",
              pl: { xs: 1, md: 1, lg: 5, xl: 5 },
              pt: 5,
            }}
          >
            <Typography variant="h5" sx={{ pb: 2 }}>
              Thank you for registering!
            </Typography>
            <Typography variant="body">
              {" "}
              Thank you for registering for the event! Your registration has
              been successfully confirmed. If you have any questions, feel free
              to contact us at events@geospectrum.com.ph. We look forward to
              seeing you!"
            </Typography>
            <Typography variant="body2" sx={{ fontStyle: "italic", pt: 5 }}>
              Important: Your access link is valid for one-time use only. Once
              used, it cannot be reused or shared.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Thankyoupage;
