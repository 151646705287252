import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  Button,
  Select,
  MenuItem,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Autocomplete,
  FormControlLabel,
  Chip,
  RadioGroup,
  Radio,
  Checkbox,
  Paper,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { filterAttendees } from "../../utils/Filter";
import { Toast, confirmDialog } from "../../utils/Alert";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addAttendee,
  fetchAttendees,
  uploadExcel,
  submitExcel,
  deleteAttendee,
  getAttendeeDetails,
  deleteRep,
  editAttendeeDets,
  sendQR,
  getEventDetails,
  //booth
  fetchBoothAttendees,
  addBoothAttendee,
  getBoothAttendeeDetails,
  editBoothAttendeeDets,
  deleteBoothAttendee,
  addRep,
} from "../../services/eventsAPI"; //API call
import {
  createCompnay,
  fetchCompanies,
  sendRegLinkRep,
} from "../../services/companyAPI";
import { addleads } from "../../services/leadsAPI";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";

//
import EventCompany from "./Event-Company";
import EventCompanyDetails from "./Event-Company-Details";

// icons
import SearchIcon from "@mui/icons-material/Search";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import EventIcon from "@mui/icons-material/Event";
import StarRateIcon from "@mui/icons-material/StarRate";
import PostAddIcon from "@mui/icons-material/PostAdd";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ApartmentIcon from "@mui/icons-material/Apartment";

// Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
// End Tabs

// Table columns
const columns = [
  {
    id: "vip",
    label: "",
    minWidth: 20,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "status",
    label: "Status",
    minWidth: 120,
    display: {
      xs: "table-cell",
      sm: "table-cell",
    },
  },
  {
    id: "level_of_interest",
    label: "Interest",
    minWidth: 50,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "name",
    label: "Name",
    minWidth: 180,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "contact",
    label: "Contact",
    minWidth: 130,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "company",
    label: "Company/Organization",
    minWidth: 225,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
];

function EventDetails({ isMarketing }) {
  const navigate = useNavigate();
  const { id, event } = useParams(); // Get the ID from the URL (event ID)
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState("");
  const [specialEvent, setSpecialEvent] = useState();
  const [seats, setSeats] = useState();
  const [remaining_seats, setRemaining_seats] = useState();
  const [orgId, setOrgId] = useState("");
  const [isPerCompany, setIsPerCompany] = useState(false); //this will set which button is displayed for adding attendee

  // Fetch event details
  const { isLoading: ldq, data: eventDetail } = useQuery({
    queryKey: ["eventDetail", id], // Ensure id is included in the query key
    queryFn: () => getEventDetails(id),
    enabled: !!id, // Prevent fetching if id is undefined
  });

  // Update state when eventDetail changes
  React.useEffect(() => {
    if (eventDetail) {
      setSpecialEvent(eventDetail.special_event);
      setSeats(eventDetail.seating_capacity);
      setRemaining_seats(eventDetail.remaining_seats);
    }
  }, [eventDetail]);

  // hide 'status' if it is not a special event
  const filteredColumns = !specialEvent
    ? columns.filter((column) => column.id !== "status")
    : columns;

  // Add Attendee Drawer
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // Attendee data
  const [attendeeData, setAttendeeData] = useState({
    // state for attendee data
    name: "",
    vip: false,
    email: "",
    contact_number: "",
    status: "",
    level_of_interest: "",
    job_title: "",
    company_name: "",
    company_email: "",
    company_contact_number: "",
    nature_of_business: "",
    notes: "",
    representatives: [],
  });

  //closes drawer and clear attendee data
  const closeDrawer = () => {
    setOpen(false);
    setAttendeeData({
      name: "",
      vip: false,
      email: "",
      contact_number: "",
      status: "",
      level_of_interest: "",
      job_title: "",
      company_name: "",
      company_email: "",
      company_contact_number: "",
      nature_of_business: "",
      notes: "",
      representatives: [],
    });
    setOrgId("");
    setIsUpload(false);
    clearExcel();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Check if it's for company_name and specialEvent is true
    if (name === "company_name" && specialEvent) {
      // Find the selected company
      const selectedCompany = companies.find(
        (cmp) => cmp.company_name === value
      );
      if (selectedCompany) {
        setOrgId(selectedCompany.company_id); // Store company_id in orgId
      }
    }

    setAttendeeData((prevState) => ({
      ...prevState,
      [name]: name === "vip" ? value === "true" : value, // Convert "true"/"false" to boolean for vip
    }));
  };

  const notification = (icon, message) => {
    Toast.fire({
      icon: icon,
      title: message,
    });
  };

  // Fetch Attendee depends if a special event or not
  const { isLoading: loadingq, data: attendees } = useQuery({
    queryKey: ["attendees", specialEvent], // Include specialEvent in query key to refetch when it changes
    queryFn: () =>
      specialEvent ? fetchAttendees(id) : fetchBoothAttendees(id),
    enabled: specialEvent !== null && specialEvent !== undefined, // Ensure specialEvent has a value before fetching
  });

  // Adding Attendee
  const addmutation = useMutation({
    mutationFn: (formData) =>
      specialEvent
        ? addAttendee(id, orgId, formData)
        : addBoothAttendee(id, formData),
    onSuccess: () => {
      closeDrawer();
      queryClient.invalidateQueries(["attendees"]);
      notification("success", "Attendee added!");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving the event.");
    },
  });

  const handleSubmitAttendee = () => {
    // Check if any field in formData is empty
    if (
      attendeeData.name === "" ||
      attendeeData.email === "" ||
      attendeeData.level_of_interest === "" ||
      attendeeData.contact_number === "" ||
      attendeeData.company === ""
    ) {
      notification("error", "Fill in required fields.");
      return;
    }
    addmutation.mutate(attendeeData);
  };

  const handleRepresentativeChange = (index, field, value) => {
    // Update the specific representative's field
    const updatedReps = [...attendeeData.representatives];
    updatedReps[index][field] = value;
    setAttendeeData((prevState) => ({
      ...prevState,
      representatives: updatedReps,
    }));
  };

  // Handle change page
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Section for uploading excel file
  const [isUpload, setIsUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [excelHeaders, setExcelHeaders] = useState([]);
  const [dbFields, setDbFields] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({});
  const [loading, setLoading] = useState(false);

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Upload file and get headers
  const uploadFile = async () => {
    if (!file) {
      notification("error", "Please select a file first.");
      return;
    }
    try {
      const response = await uploadExcel(id, file);
      setExcelHeaders(response.data.excel_headers);
      setDbFields(response.data.column_names);
      notification(
        "success",
        "Headers loaded successfully. Match the fields below."
      );
    } catch (error) {
      notification("error", "Error loading headers. Please try again.");
    }
  };

  // Handle field mapping change
  const handleMappingChange = (dbField, e) => {
    const selectedHeader = e.target.value;

    setFieldMapping((prev) => ({
      ...prev,
      [dbField]: selectedHeader, // Map the selected header to the corresponding dbField
    }));
  };

  const addBulkmutation = useMutation({
    mutationFn: ({ id, file, fieldMapping }) =>
      submitExcel(id, file, fieldMapping),
    onSuccess: () => {
      closeDrawer();
      queryClient.invalidateQueries(["attendees"]);
      notification("success", "Attendees added!");
      setLoading(false);
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving the event.");
    },
  });

  // Submit the mapped fields and data
  const submitData = async () => {
    const requiredFields = [
      "name",
      "email",
      "contact_number",
      // "level_of_interest",
      "company_name",
    ];
    const unmappedFields = requiredFields.filter(
      (field) => !Object.keys(fieldMapping).includes(field)
    );

    if (unmappedFields.length > 0) {
      notification(
        "error",
        `Please map the following fields: ${unmappedFields.join(", ")}`
      );
      return;
    }

    setLoading(true);
    addBulkmutation.mutate({ id, file, fieldMapping });
  };

  const clearExcel = async () => {
    setDbFields([]);
    setExcelHeaders([]);
    setFile(null);
    setFieldMapping({});
  };

  // edit attendee section
  const [attendeeID, setAttendeeID] = useState(); // selected attendee ID

  const editAttendee = async (attendee_ID, org_id) => {
    setOpenEdit(true);
    setAttendeeID(attendee_ID);
    // console.log(hideSearch);
    // Conditionally choose the correct function
    const selected_attendee = specialEvent
      ? await getAttendeeDetails(attendee_ID, id, org_id)
      : await getBoothAttendeeDetails(attendee_ID, id);
    setAttendeeData(selected_attendee);
    setOrgId(selected_attendee.company_id);
  };

  // Edit Attendee
  const editAttendeemutation = useMutation({
    mutationFn: ({ attendee_ID, org_id }) =>
      specialEvent
        ? editAttendeeDets(attendee_ID, id, org_id, attendeeData)
        : editBoothAttendeeDets(attendee_ID, id, attendeeData),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["attendees"]);
      notification("success", "Attendee updated!");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving the event.");
    },
  });

  const handleEditAttendee = (attendee_ID, org_id) => {
    // Check if any field in formData is empty
    if (
      attendeeData.name === "" ||
      attendeeData.email === "" ||
      attendeeData.level_of_interest === "" ||
      attendeeData.contact_number === ""
      // attendeeData.status === ""
    ) {
      notification("error", "Fill in required fields.");
      return;
    }
    editAttendeemutation.mutate({ attendee_ID, org_id });
  };

  // Edit Attendee Drawer
  const [openEdit, setOpenEdit] = React.useState(false);
  //closes drawer and clear attendee data
  const closeDrawerEdit = () => {
    setOpenEdit(false);
    setIsAddrep(false);
    setRepresentatives([{ name: "", email: "", contact_number: "", role: "" }]);
    setAttendeeData({
      name: "",
      vip: false,
      email: "",
      contact_number: "",
      status: "",
      level_of_interest: "",
      job_title: "",
      company_name: "",
      company_email: "",
      company_contact_number: "",
      nature_of_business: "",
      notes: "",
      representatives: [],
    });
  };

  // Delete Attendee______________________
  const deleteAttendeeMutation = useMutation({
    mutationFn: (org_id) =>
      specialEvent
        ? deleteAttendee(attendeeID, id, org_id)
        : deleteBoothAttendee(attendeeID, id),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["attendees"]);
      notification("success", "Attendee successfully deleted");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while deleting the attendee.");
    },
  });

  const handleDeleteAttendee = async (org_id) => {
    const userConfirmed = await confirmDialog({
      title: "Delete attendee?",
      text: "This action cannot be undone.",
    });
    if (userConfirmed) {
      deleteAttendeeMutation.mutate(org_id);
    }
  };

  // Delete Representative______________________
  const deleteRepMutation = useMutation({
    mutationFn: ({ visitorID, repID, orgId }) =>
      deleteRep(id, visitorID, repID, orgId),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["attendees"]);
      notification("success", "Representative successfully deleted");
    },
  });

  const handleDeleteRep = async (visitorID, repID) => {
    const userConfirmed = await confirmDialog({
      title: "Delete representative?",
      text: "This action cannot be undone.",
    });
    if (userConfirmed) {
      deleteRepMutation.mutate({ visitorID, repID, orgId });
    }
  };

  // Add representative section

  // Adding more representative (ADD AND INVITE; SEND REGISTRATION)
  const [rep_action, setRep_action] = useState(""); // "qr" or "custom"
  const handleRepActionChange = (type) => {
    // console.log(type);
    setRep_action(type);
  };

  // Adding more representative without registration link (ADD ONLY)
  const handleAddRepresentative = () => {
    setRepresentatives((prevState) => [
      ...prevState,
      { name: "", email: "", contact_number: "", company_name: "", role: "" },
    ]);
  };

  const handleAddRepresentativeChange = (index, field, value) => {
    // Create a shallow copy of the representatives array
    const updatedReps = [...representatives];

    // Update the specific field of the representative at the given index
    updatedReps[index] = { ...updatedReps[index], [field]: value };

    // Update state with the modified representatives array
    setRepresentatives(updatedReps);
  };

  const handleAddRepDrawer = () => {
    setIsAddrep(true);
  };

  const [isAddrep, setIsAddrep] = React.useState(false);
  const [representatives, setRepresentatives] = React.useState([
    { name: "", email: "", contact_number: "", role: "" },
  ]);

  const handleAddRep = async (visitorID) => {
    // console.log(representatives);
    if (
      representatives.name === "" ||
      representatives.email === "" ||
      representatives.role === "" ||
      representatives.contact_number === ""
    ) {
      notification("info", "Please input required field");
    } else {
      const userConfirmed = await confirmDialog({
        title: "Add representative?",
        text: "Representaive/s will be added on the selected attendee.",
      });
      if (userConfirmed) {
        AddRepMutation.mutate({ visitorID, orgId });
      }
    }
  };

  const AddRepMutation = useMutation({
    mutationFn: ({ visitorID, orgId }) =>
      rep_action === "Add"
        ? addRep(id, orgId, visitorID, representatives)
        : sendRegLinkRep(id, orgId, visitorID, representatives),
    onSuccess: () => {
      closeDrawerEdit();
      setRep_action("");
      setRepresentatives([
        {
          name: "",
          email: "",
          contact_number: "",
          company_name: "",
          role: "",
        },
      ]);
      setIsAddrep(false);
      queryClient.invalidateQueries(["attendees"]);
      notification("success", "Representative successfully added");
    },
  });

  // Email Dialog_________
  const [refetch, setRefetch] = React.useState(false); //trigger that allows refetch on the updated confirmed attendees
  // Filter confirmed attendees once attendees data is fetched
  React.useEffect(() => {
    if (attendees && attendees.length > 0) {
      const attendeeList = specialEvent
        ? attendees
        : attendees[0]?.booth_event_attendees;

      if (attendeeList) {
        // Filter attendees with status 'Confirmed'
        const filtered = attendeeList.filter(
          (attd) => attd.status === "Confirmed"
        );
        setConfirmedAttendees(filtered);
      } else {
        console.log("no attendee");
      }
    }
  }, [attendees, specialEvent, refetch]);

  const [confirmedAttendees, setConfirmedAttendees] = useState([]);
  const [selectedAttendees, setSelectedAttendees] = useState([]); //selected attendees on the autocomplete
  const [finalAttendees, setFinalAttendees] = useState([]); // final attendees to send email (QR)
  const [action, setAction] = useState(""); // "qr" or "custom"
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
    setAction("");
    setSelectAll(false);
    setSelectedAttendees(null);
    setFinalAttendees([]);
  };

  const handleActionChange = (type) => {
    setAction(type);
  };

  // Autocomplete Section
  const handleSelect = (event, newValue) => {
    const employeeData = newValue.map((emp) => ({
      attendeeId: emp.attendee_id,
      name: emp.name,
      companyId: emp.company_id,
      qr_count: emp.qr_count ? emp.qr_count : 0,
      isRepPresent: emp.length === 0 ? true : false,
    }));
    setSelectedAttendees(employeeData);
  };

  // Checkbox to select all Confirmed Attendee
  const handleSelectAll = (event) => {
    const newSelectAll = event.target.checked;
    setSelectAll(newSelectAll);

    // Reset selected attendees if "select all" is unchecked
    if (!newSelectAll) {
      setSelectedAttendees(null);
      return;
    }
    // console.log(confirmedAttendees)
    const employeeData = confirmedAttendees.map((emp) => ({
      attendeeId: emp.attendee_id,
      name: emp.name,
      companyId: emp.company_id,
      qr_count: emp.qr_count ? emp.qr_count : 0,
      isRepPresent: emp.attendee_representatives.length === 0 ? true : false,
    }));

    setSelectedAttendees(employeeData);
  };

  const handleRadioChange = (attd, value) => {
    // console.log(attd);
    const updatedFinalAttendees = [...finalAttendees];
    const existingIndex = updatedFinalAttendees.findIndex(
      (attendee) => attendee.id === attd.id
    );

    if (existingIndex !== -1) {
      updatedFinalAttendees[existingIndex] = {
        attendeeId: attd.attendeeId,
        companyId: attd.companyId,
        main: value === "Main Attendee" ? true : false,
      };
    } else {
      updatedFinalAttendees.push({
        attendeeId: attd.attendeeId,
        companyId: attd.companyId,
        main: value === "Main Attendee" ? true : false,
      });
    }

    setFinalAttendees(updatedFinalAttendees);
  };

  const handleConfirmEmail = async () => {
    if (finalAttendees.length <= 0) {
      notification("info", "Please select an attendee");
    } else {
      sendQRMutation.mutate();
    }
  };

  const sendQRMutation = useMutation({
    mutationFn: (org_id) => sendQR(finalAttendees, id),
    onSuccess: () => {
      notification("success", "QR Sent Successfully!");
      handleClose();
      setRefetch(!refetch); //trigger refetch on confirmed attendees
      queryClient.invalidateQueries(["attendees"]);
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while deleting the attendee.");
    },
  });

  const [toLeads, setToLeads] = useState([]);

  // Copy to leads
  const copytoLeads = async () => {
    setToLeads({
      project_name: "Event:" + event,
      company_name: attendeeData.company_name,
      implementing_agency: "",
      company_email: attendeeData.company_email,
      company_contact_number: attendeeData.company_contact_number,
      estimated_value: "10",
      nature_of_business: attendeeData.nature_of_business,
      interaction_logs: [],
      action_items: [],
      status: "Outbound",
      level_of_priority: "",
      product_interested_in: [],
      source: event,
      contact_person: [],
      champion_details: [
        {
          name: attendeeData.visitor_name,
          email: attendeeData.email,
          phone_number: attendeeData.contact_number,
          job_title: attendeeData.job_title,
        },
      ],
    });

    const userConfirmed = await confirmDialog({
      title: "Copy attendee?",
      text: "Details will be copy to Leads",
    });
    if (userConfirmed) {
      copyLeadmutation.mutate();
    }
  };

  // Copy attendeed to Leads
  const copyLeadmutation = useMutation({
    mutationFn: () => addleads(toLeads),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["leads"]);
      notification("success", "Copied Successfully !");
    },
    onError: (error) => {
      console.log(error);
      notification("error", error.response.data.error);
    },
  });

  // Cleanup logic when exiting
  React.useEffect(() => {
    return () => {
      // Optionally clear cache or perform additional cleanup
      queryClient.removeQueries(["attendees", id]); // Removes this specific query's cache
    };
  }, [id, queryClient]);

  const asteriskStyle = { color: "#c20a0a" };

  // Tab selection
  const [value, setValue] = React.useState(0);
  const handleClickChip = (newValue) => {
    setValue(newValue);
    if (newValue == 0) {
      setIsPerCompany(false);
    } else {
      setIsPerCompany(true);
    }
  };

  // Function when selecting a company, from the Event-Company it passes the name and Org ID and will pass to the Event-Company-Details component
  const [selectedComp, setSelectedComp] = useState({
    name: "",
    id: false,
  });

  const [hideSearch, setHideSearch] = useState(false);
  const handleClickCompany = async (newValue, comp_name, orgId) => {
    setSelectedComp({
      name: comp_name,
      id: orgId,
    });
    // setHideSearch(true);
    setValue(newValue);
  };

  // Company section
  const [openAddComp, setOpenAddComp] = React.useState(false);

  //closes drawer and clear data
  const closeDrawerComp = () => {
    setOpenAddComp(false);
    setCompData([]);
  };

  // Company data
  const [compData, setCompData] = useState({
    // state for attendee data
    company_name: "",
    alloted_seats: "",
    company_email: "",
    company_contact_number: "",
    nature_of_business: "",
  });

  const handleChangeCompany = (event) => {
    const { name, value } = event.target;

    setCompData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toggleAddCompDrawer = (newOpen) => () => {
    setOpenAddComp(newOpen);
  };

  // Adding Company
  const addCompanymutation = useMutation({
    mutationFn: (formData) => createCompnay(id, formData),
    onSuccess: () => {
      closeDrawerComp();
      queryClient.invalidateQueries(["companies", "eventDetail"]);
      notification("success", "Company added!");
    },
    onError: (error) => {
      console.error(error);
      notification("error", error);
    },
  });

  const handleSubmitCompany = () => {
    // Check if any field in formData is empty
    if (
      compData.company_name === "" ||
      compData.alloted_seats === "" ||
      compData.nature_of_business === "" ||
      compData.company_contact_number === "" ||
      compData.company_email === ""
    ) {
      notification("error", "Fill in required fields.");
      return;
    }
    addCompanymutation.mutate(compData);
  };

  // Fetch companies: fetch companies if special event only
  const { isLoading, data: companies } = useQuery({
    queryKey: ["companies"],
    queryFn: () => (specialEvent ? fetchCompanies(id) : null),
    enabled: specialEvent !== null && specialEvent !== undefined,
  });

  if (ldq || isLoading) return <div>Loading...</div>; //loading state
  const filteredData = filterAttendees(
    specialEvent
      ? isPerCompany
        ? companies || [] // If isPerCompany is true
        : attendees // Otherwise, use attendees[0].attendees
      : attendees?.[0]?.booth_event_attendees || [], // If specialEvent is false, use booth_event_attendees
    // Fallback to an empty array if attendees is empty or undefined
    searchTerm
  );

  return (
    <>
      {/* Main */}
      <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ mb: 2 }} variant="h5">
          {event}
          {specialEvent && (
            <Chip
              sx={{ ml: 1 }}
              size="small"
              color="default"
              label={remaining_seats + "/" + seats}
            />
          )}
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 3, md: 2, lg: 2, xl: 2 }}>
            {isMarketing && (
              <>
                {!isPerCompany && (
                  <Button
                    variant="contained"
                    startIcon={<PersonAddAltIcon />}
                    size="small"
                    onClick={toggleDrawer(true)}
                  >
                    Add Attendee
                  </Button>
                )}
                {isPerCompany && (
                  <>
                    <Button
                      variant="contained"
                      startIcon={<ApartmentIcon />}
                      size="small"
                      onClick={toggleAddCompDrawer(true)}
                      disabled={value === 2}
                    >
                      Add Company
                    </Button>
                  </>
                )}
              </>
            )}
          </Grid>
          <Grid size={{ xs: 9, md: 4, lg: 2, xl: 2 }}>
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "row",
                gap: 2,
                overflow: "auto",
                pb: 1,
                ml: 4,
              }}
            >
              <Chip
                onClick={() => handleClickChip(0)}
                size="small"
                label="All Attendees"
                sx={{
                  backgroundColor: value === 0 ? "#e6e6e6" : "transparent",
                  color: value === 0 ? "black" : "inherit",
                  cursor: "pointer",
                }}
              />
              {specialEvent && (
                <Chip
                  onClick={() => handleClickChip(1)}
                  size="small"
                  label="Per Company"
                  sx={{
                    backgroundColor: value === 1 ? "#e6e6e6" : "transparent",
                    color: value === 1 ? "black" : "inherit",
                    cursor: "pointer",
                  }}
                />
              )}
            </Box>
          </Grid>
          <Grid
            size={{ xs: 12, md: 6, lg: 8, xl: 8 }}
            container
            justifyContent={{
              xs: "flex-start",
              md: "flex-start",
              lg: "flex-end",
              xl: "flex-end",
            }}
            alignItems={"center"}
          >
            {isMarketing && specialEvent && !hideSearch && (
              <>
                {" "}
                <IconButton
                  title="Scan QR"
                  onClick={() => navigate(`/events/QR/${id}`)}
                >
                  <QrCodeScannerIcon />
                </IconButton>
                <IconButton
                  title="Send Email"
                  onClick={() => handleClickOpen()}
                >
                  <MailOutlineIcon />
                </IconButton>
              </>
            )}
            {!hideSearch && (
              <TextField
                label="Search"
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ width: { xs: "65%", md: "auto" } }}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            )}
          </Grid>

          <Grid size={12} sx={{ mb: 2 }}>
            {/* Tab 1 shows all attendeed */}
            <CustomTabPanel value={value} index={0}>
              <TableContainer
                sx={{
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {filteredColumns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                          sx={{
                            pl: "10px",
                            pr: "10px",
                            display: column.display || "table-cell",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      // Display a loading indicator properly wrapped in table elements
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          Loading...
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => (
                          <TableRow
                            key={index}
                            onClick={() =>
                              specialEvent
                                ? editAttendee(row.attendee_id, row.company_id)
                                : editAttendee(row._id)
                            }
                            className="table-row"
                          >
                            <TableCell
                              align="left"
                              sx={{ pl: "10px", pr: "10px",   display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              }, }}
                            >
                              {row.vip ? <StarRateIcon fontSize="small" /> : ""}
                            </TableCell>
                            {/*Hide status cell if special event is false  */}
                            {specialEvent && (
                              <TableCell
                                align="left"
                                sx={{ pl: "10px", pr: "10px" }}
                              >
                                <Stack direction="row">
                                  <Chip
                                    size="small"
                                    color={
                                      row.status === "Confirmed"
                                        ? "primary"
                                        : row.status === "No-show"
                                          ? "secondary"
                                          : row.status === "Attended"
                                            ? "success"
                                            : row.status === "Rejected"
                                              ? "error"
                                              : "default" // or any other default color
                                    }
                                    label={row.status}
                                  />
                                </Stack>
                              </TableCell>
                            )}

                            <TableCell
                              align="left"
                              sx={{
                                pl: "10px",
                                pr: "10px",
                                display: {
                                  xs: "none", // Hide on extra-small screens
                                  sm: "table-cell", // Show on small screens and larger
                                },
                              }}
                            >
                              {row.level_of_interest}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ pl: "10px", pr: "10px" }}
                            >
                              {row.name}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                pl: "10px",
                                pr: "10px",
                                display: {
                                  xs: "none", // Hide on extra-small screens
                                  sm: "table-cell", // Show on small screens and larger
                                },
                              }}
                            >
                              {row.email}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                pl: "10px",
                                pr: "10px",
                                display: {
                                  xs: "none", // Hide on extra-small screens
                                  sm: "table-cell", // Show on small screens and larger
                                },
                              }}
                            >
                              {row.contact_number}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                pl: "10px",
                                pr: "10px",
                                display: {
                                  xs: "none", // Hide on extra-small screens
                                  sm: "table-cell", // Show on small screens and larger
                                },
                              }}
                            >
                              {row.company_name}
                            </TableCell>
                          </TableRow>
                        ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={
                  specialEvent
                    ? attendees?.[0]?.attendees?.length || 0
                    : attendees?.[0]?.booth_event_attendees?.length || 0
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </CustomTabPanel>

            {/* Tab 2 Per Company */}
            <CustomTabPanel value={value} index={1}>
              <EventCompany
                filteredData={filteredData}
                clickCompanyFunction={handleClickCompany}
                isMarketing={isMarketing}
              />
            </CustomTabPanel>

            {/* Tab 3 Company Details */}
            <CustomTabPanel value={value} index={2}>
              <EventCompanyDetails
                selectedCompanyData={selectedComp}
                isMarketing={isMarketing}
              />
            </CustomTabPanel>
          </Grid>
        </Grid>
      </Box>

      {/* Drawer for Adding an Attendee */}
      <Drawer open={open} onClose={() => closeDrawer()}>
        <Box
          className="thin-scrollbar"
          sx={{ width: { xs: 360, lg: 480, xl: 550 }, mt: 8, p: 2 }}
          role="presentation"
        >
          {isUpload ? (
            <>
              <Grid container justifyContent={"center"} rowSpacing={0.5}>
                <Grid size={12} display="flex" alignItems="center">
                  <EventIcon />
                  <Typography variant="body" sx={{ ml: 1 }}>
                    Excel Bulk Upload
                  </Typography>
                </Grid>

                <input
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleFileChange}
                  style={{ marginBottom: "16px" }}
                />
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={uploadFile}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Upload"}
                </Button>

                {/* Field Matching Section */}
                {dbFields.length > 0 && excelHeaders.length > 0 && (
                  <>
                    <Grid size={12} display="flex" alignItems="center">
                      <Typography variant="h6" gutterBottom>
                        Match Fields
                      </Typography>
                    </Grid>
                    {dbFields.map((field, index) => (
                      <React.Fragment key={index}>
                        <Grid size={12} display="flex">
                          <TextField
                            size="small"
                            select
                            sx={{ mt: 0.5 }}
                            label={field || "Database Field"}
                            onChange={(e) => handleMappingChange(field, e)}
                            value={fieldMapping[field] || ""}
                            fullWidth
                            variant="outlined"
                          >
                            <MenuItem value="" disabled>
                              Select an Excel header
                            </MenuItem>
                            {excelHeaders
                              .filter(
                                (header) =>
                                  !Object.values(fieldMapping).includes(
                                    header
                                  ) || fieldMapping[field] === header
                              )
                              .map((header, idx) => (
                                <MenuItem key={idx} value={header}>
                                  {header}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                      </React.Fragment>
                    ))}

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submitData}
                      disabled={loading}
                    >
                      {loading ? "Uploading..." : "Submit Data"}
                    </Button>
                  </>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid size={10} display="flex" alignItems="center">
                  <EventIcon />
                  <Typography variant="body" sx={{ ml: 1 }}>
                    Add Attendee
                  </Typography>
                </Grid>
                <Grid size={2} display="flex" justifyContent="flex-end">
                  {!specialEvent && (
                    <IconButton
                      title="Import Excel"
                      onClick={() => setIsUpload(true)}
                    >
                      <PostAddIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid size={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container rowSpacing={0.5} columnSpacing={1} sx={{ mt: 1 }}>
                <Grid size={10}>
                  <Typography variant="body2">
                    Name <span style={asteriskStyle}>*</span>
                  </Typography>
                </Grid>
                <Grid size={2}>
                  <Typography variant="body2">VIP?</Typography>
                </Grid>
                <Grid size={9}>
                  <TextField
                    fullWidth
                    size="small"
                    name="name"
                    value={attendeeData.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={3}>
                  <Select
                    size="small"
                    fullWidth
                    name="vip"
                    value={String(attendeeData.vip)} // Convert boolean to string for the Select component
                    onChange={handleChange}
                  >
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </Grid>
                {specialEvent && (
                  <Grid size={6}>
                    <Typography variant="body2">
                      Status <span style={asteriskStyle}>*</span>
                    </Typography>
                  </Grid>
                )}

                <Grid size={!specialEvent ? 12 : 6}>
                  <Typography variant="body2">
                    Level of Interest <span style={asteriskStyle}>*</span>
                  </Typography>
                </Grid>
                {specialEvent && (
                  <Grid size={6}>
                    <Select
                      size="small"
                      fullWidth
                      name="status"
                      value={attendeeData.status}
                      onChange={handleChange}
                    >
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Confirmed">Confirmed</MenuItem>
                      <MenuItem value="Rejected">Rejected</MenuItem>
                      <MenuItem value="Attended">Attended</MenuItem>
                      <MenuItem value="No-show">No-show</MenuItem>
                    </Select>
                  </Grid>
                )}
                <Grid size={!specialEvent ? 12 : 6}>
                  <Select
                    size="small"
                    fullWidth
                    name="level_of_interest"
                    value={attendeeData.level_of_interest}
                    onChange={handleChange}
                  >
                    <MenuItem value="Potential">Potential</MenuItem>
                    <MenuItem value="Casual">Casual</MenuItem>
                  </Select>
                </Grid>

                <Grid size={12}>
                  <Typography variant="body2">Role / Position</Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    name="job_title"
                    value={attendeeData.job_title}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">
                    Email <span style={asteriskStyle}>*</span>
                  </Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">
                    Phone <span style={asteriskStyle}>*</span>
                  </Typography>
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    name="email"
                    value={attendeeData.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    name="contact_number"
                    type="number"
                    value={attendeeData.contact_number}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={12}>
                  <Typography variant="body2">
                    Company / Organization <span style={asteriskStyle}>*</span>
                  </Typography>
                </Grid>
                {!specialEvent ? (
                  <Grid size={12}>
                    <TextField
                      fullWidth
                      size="small"
                      name="company_name"
                      value={attendeeData.company_name}
                      onChange={handleChange}
                    />
                  </Grid>
                ) : (
                  <Grid size={12}>
                    <Select
                      size="small"
                      fullWidth
                      name="company_name"
                      value={attendeeData.company_name}
                      onChange={handleChange}
                    >
                      {companies?.map((cmp, index) => (
                        <MenuItem key={index} value={cmp.company_name}>
                          {cmp.company_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}

                <Grid size={12}>
                  <Typography variant="body2">Industry</Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    name="nature_of_business"
                    value={attendeeData.nature_of_business}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">Company / Org. Email</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">Company / Org. Phone</Typography>
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    name="company_email"
                    value={attendeeData.company_email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    name="company_contact_number"
                    value={attendeeData.company_contact_number}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid size={12}>
                  <Typography variant="body2">Note</Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={3}
                    name="notes"
                    value={attendeeData.notes}
                    onChange={handleChange}
                  />
                </Grid>

                {/* {attendeeData?.representatives?.map((rep, index) => (
                  <React.Fragment key={index}>
                    <Grid size={12} sx={{ mt: 2, mb: 2 }}>
                      <Divider />
                    </Grid>
                    <Grid size={12}>
                      <Typography variant="body2">
                        Representative Name
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        value={rep.name}
                        onChange={(e) =>
                          handleRepresentativeChange(
                            index,
                            "name",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid size={12}>
                      <Typography variant="body2">Role / Position</Typography>
                      <TextField
                        fullWidth
                        size="small"
                        value={rep.role}
                        onChange={(e) =>
                          handleRepresentativeChange(
                            index,
                            "role",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid size={6}>
                      <Typography variant="body2">Email</Typography>
                      <TextField
                        fullWidth
                        size="small"
                        value={rep.email}
                        onChange={(e) =>
                          handleRepresentativeChange(
                            index,
                            "email",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid size={6}>
                      <Typography variant="body2">Phone Number</Typography>
                      <TextField
                        fullWidth
                        size="small"
                        value={rep.contact_number}
                        onChange={(e) =>
                          handleRepresentativeChange(
                            index,
                            "contact_number",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                  </React.Fragment>
                ))} */}

                {/* {specialEvent && (
                  <>
                    <Grid size={2}>
                      <IconButton
                        title="Add Representative"
                        onClick={handleAddRepresentative}
                      >
                        <PersonAddAltIcon />
                      </IconButton>
                    </Grid>
                    <Grid size={10} alignContent="center">
                      <Typography variant="body2">
                        Click to add Representative/s.
                      </Typography>
                    </Grid>
                  </>
                )} */}

                <Grid
                  size={12}
                  container
                  justifyContent="flex-end"
                  sx={{ mt: 3 }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={closeDrawer}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleSubmitAttendee}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Drawer>

      {/* Drawer for Editing an Attendee */}
      <Drawer open={openEdit} onClose={() => closeDrawerEdit()}>
        {!isAddrep ? (
          <Box
            className="thin-scrollbar"
            sx={{ width: { xs: 360, lg: 480, xl: 550 }, mt: 8, p: 2 }}
            role="presentation"
          >
            <Grid container spacing={2} justifyContent={"center"}>
              <Grid size={12} display="flex" alignItems="center">
                <EventIcon />
                <Typography variant="body" sx={{ ml: 1 }}>
                  Edit Attendee
                </Typography>
              </Grid>
              <Grid size={12}>
                <Divider />
              </Grid>
            </Grid>
            <Grid container rowSpacing={0.5} columnSpacing={1} sx={{ mt: 1 }}>
              <Grid size={10}>
                <Typography variant="body2">
                  Name <span style={asteriskStyle}>*</span>
                </Typography>
              </Grid>
              <Grid size={2}>
                <Typography variant="body2">VIP?</Typography>
              </Grid>
              <Grid size={9}>
                <TextField
                  fullWidth
                  size="small"
                  disabled={!isMarketing}
                  name="visitor_name"
                  value={attendeeData.visitor_name || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={3}>
                <Select
                  size="small"
                  disabled={!isMarketing}
                  fullWidth
                  name="vip"
                  value={
                    attendeeData.vip !== undefined
                      ? String(attendeeData.vip)
                      : ""
                  } // Convert boolean to string for the Select component
                  onChange={handleChange}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </Grid>
              {specialEvent && (
                <Grid size={6}>
                  <Typography variant="body2">
                    Status <span style={asteriskStyle}>*</span>
                  </Typography>
                </Grid>
              )}
              <Grid size={!specialEvent ? 12 : 6}>
                <Typography variant="body2">
                  Level of Interest <span style={asteriskStyle}>*</span>
                </Typography>
              </Grid>
              {specialEvent && (
                <Grid size={6}>
                  <Select
                    size="small"
                    disabled={!isMarketing}
                    fullWidth
                    name="status"
                    value={attendeeData.status || ""}
                    onChange={handleChange}
                  >
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Confirmed">Confirmed</MenuItem>
                    <MenuItem value="Rejected">Rejected</MenuItem>
                    <MenuItem value="Attended">Attended</MenuItem>
                    <MenuItem value="No-show">No-show</MenuItem>
                  </Select>
                </Grid>
              )}
              <Grid size={!specialEvent ? 12 : 6}>
                <Select
                  size="small"
                  disabled={!isMarketing}
                  fullWidth
                  name="level_of_interest"
                  value={attendeeData.level_of_interest || ""}
                  onChange={handleChange}
                >
                  <MenuItem value="Potential">Potential</MenuItem>
                  <MenuItem value="Casual">Casual</MenuItem>
                </Select>
              </Grid>

              <Grid size={12}>
                <Typography variant="body2">Role / Position</Typography>
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  size="small"
                  disabled={!isMarketing}
                  name="job_title"
                  value={attendeeData.job_title || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={6}>
                <Typography variant="body2">
                  Email <span style={asteriskStyle}>*</span>
                </Typography>
              </Grid>
              <Grid size={6}>
                <Typography variant="body2">
                  Phone <span style={asteriskStyle}>*</span>
                </Typography>
              </Grid>
              <Grid size={6}>
                <TextField
                  fullWidth
                  size="small"
                  disabled={!isMarketing}
                  name="email"
                  value={attendeeData.email || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={6}>
                <TextField
                  fullWidth
                  size="small"
                  disabled={!isMarketing}
                  name="contact_number"
                  type="number"
                  value={attendeeData.contact_number || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid size={12}>
                <Typography variant="body2">Company / Organization</Typography>
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  size="small"
                  name="company_name"
                  value={attendeeData.company_name || ""}
                  onChange={handleChange}
                  disabled={specialEvent}
                />
              </Grid>
              <Grid size={12}>
                <Typography variant="body2">Industry</Typography>
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  size="small"
                  disabled={specialEvent}
                  name="nature_of_business"
                  value={attendeeData.nature_of_business || ""}
                />
              </Grid>
              <Grid size={6}>
                <Typography variant="body2">Company / Org. Email</Typography>
              </Grid>
              <Grid size={6}>
                <Typography variant="body2">Company / Org. Phone</Typography>
              </Grid>
              <Grid size={6}>
                <TextField
                  fullWidth
                  size="small"
                  disabled={specialEvent}
                  name="company_email"
                  value={attendeeData.company_email || ""}
                />
              </Grid>
              <Grid size={6}>
                <TextField
                  fullWidth
                  size="small"
                  name="company_contact_number"
                  type="number"
                  value={attendeeData.company_contact_number || ""}
                  disabled={specialEvent}
                />
              </Grid>
              <Grid size={12}>
                <Typography variant="body2">Note</Typography>
              </Grid>
              <Grid size={12}>
                <TextField
                  fullWidth
                  size="small"
                  disabled={!isMarketing}
                  multiline
                  rows={3}
                  name="notes"
                  value={attendeeData.notes || ""}
                  onChange={handleChange}
                />
              </Grid>
              {specialEvent && (
                <>
                  {attendeeData.representatives.map((rep, index) => (
                    <React.Fragment key={index}>
                      <Grid size={12} sx={{ mt: 2, mb: 2 }}>
                        <Divider />
                      </Grid>
                      <Grid size={12}>
                        <Typography variant="body2">
                          Representative Name {index + 1}
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          disabled={!isMarketing}
                          value={rep.name || ""}
                          onChange={(e) =>
                            handleRepresentativeChange(
                              index,
                              "name",
                              e.target.value
                            )
                          }
                          slotProps={{
                            input: {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    disabled={!isMarketing}
                                    title="Delete Representative"
                                    onClick={() =>
                                      handleDeleteRep(
                                        attendeeData.attendee_id,
                                        rep._id,
                                        attendeeData.company_id
                                      )
                                    }
                                  >
                                    <PersonRemoveIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            },
                          }}
                        />
                      </Grid>
                      <Grid size={12}>
                        <Typography variant="body2">Role / Position</Typography>
                        <TextField
                          fullWidth
                          size="small"
                          disabled={!isMarketing}
                          value={rep.role || ""}
                          onChange={(e) =>
                            handleRepresentativeChange(
                              index,
                              "role",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid size={6}>
                        <Typography variant="body2">Email</Typography>
                        <TextField
                          fullWidth
                          size="small"
                          disabled={!isMarketing}
                          value={rep.email || ""}
                          onChange={(e) =>
                            handleRepresentativeChange(
                              index,
                              "email",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid size={6}>
                        <Typography variant="body2">Phone Number</Typography>
                        <TextField
                          fullWidth
                          disabled={!isMarketing}
                          size="small"
                          value={rep.contact_number || ""}
                          onChange={(e) =>
                            handleRepresentativeChange(
                              index,
                              "contact_number",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                </>
              )}
              <Grid size={12}>
                <Divider />
              </Grid>
              {isMarketing && (
                <>
                  {specialEvent && (
                    <>
                      <Grid size={2}>
                        <IconButton
                          title="Add Representative"
                          onClick={handleAddRepDrawer}
                        >
                          <PersonAddAltIcon />
                        </IconButton>
                      </Grid>
                      <Grid size={10} alignContent={"center"}>
                        <Typography variant="body2">
                          Click to add Representative/s.
                        </Typography>
                      </Grid>
                    </>
                  )}
                  <Grid
                    size={12}
                    container
                    justifyContent="flex-end"
                    sx={{ mt: 3 }}
                  >
                    <IconButton
                      title="Delete"
                      onClick={() =>
                        specialEvent
                          ? handleDeleteAttendee(attendeeData.company_id)
                          : handleDeleteAttendee()
                      }
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                    <Button
                      variant="contained"
                      size="small"
                      color="outboundColor"
                      onClick={copytoLeads}
                      sx={{ mr: 0.5 }}
                    >
                      Copy to Leads
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      sx={{ mr: 0.5 }}
                      onClick={closeDrawerEdit}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ mr: 0.5 }}
                      onClick={() =>
                        specialEvent
                          ? handleEditAttendee(
                              attendeeData.attendee_id,
                              attendeeData.company_id
                            )
                          : handleEditAttendee(attendeeData.attendee_id)
                      }
                    >
                      Update
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        ) : (
          <>
            {" "}
            {/* Drawer to add a new representative */}
            <Box
              className="thin-scrollbar"
              sx={{ width: { xs: 360, lg: 480, xl: 550 }, mt: 8, p: 2 }}
              role="presentation"
            >
              <Grid container rowSpacing={0.5} columnSpacing={1} sx={{ mt: 1 }}>
                <Grid size={6} display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rep_action === "Add"}
                        onChange={() => handleRepActionChange("Add")}
                      />
                    }
                    label="Add"
                  />
                </Grid>
                <Grid size={6} display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rep_action === "Add & Invite"}
                        onChange={() => handleRepActionChange("Add & Invite")}
                      />
                    }
                    label="Add & Invite"
                  />
                </Grid>

                {/* Add representative only */}
                {rep_action === "Add" ? (
                  <>
                    {representatives.map((rep, index) => (
                      <React.Fragment key={index}>
                        <Grid size={12}>
                          <Typography variant="body2">
                            Representative Name {index + 1}
                          </Typography>
                          <TextField
                            fullWidth
                            size="small"
                            disabled={!isMarketing}
                            value={rep.name || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={12}>
                          <Typography variant="body2">
                            Role / Position
                          </Typography>
                          <TextField
                            fullWidth
                            size="small"
                            disabled={!isMarketing}
                            value={rep.role || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "role",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={6}>
                          <Typography variant="body2">Email</Typography>
                          <TextField
                            fullWidth
                            size="small"
                            disabled={!isMarketing}
                            value={rep.email || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "email",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={6}>
                          <Typography variant="body2">Phone Number</Typography>
                          <TextField
                            fullWidth
                            disabled={!isMarketing}
                            size="small"
                            value={rep.contact_number || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "contact_number",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={2}>
                          <IconButton
                            title="Add Representative"
                            onClick={handleAddRepresentative}
                          >
                            <PersonAddAltIcon />
                          </IconButton>
                        </Grid>
                        <Grid size={10} alignContent="center">
                          <Typography variant="body2">
                            Click to add Representative/s.
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </>
                ) : rep_action === "Add & Invite" ? (
                  <>
                    {representatives.map((rep, index) => (
                      <React.Fragment key={index}>
                        <Grid size={12}>
                          <Typography variant="body2">
                            Representative Name {index + 1}
                          </Typography>
                          <TextField
                            fullWidth
                            size="small"
                            disabled={!isMarketing}
                            value={rep.name || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "name",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={12}>
                          <Typography variant="body2">
                            Role / Position
                          </Typography>
                          <TextField
                            fullWidth
                            size="small"
                            disabled={!isMarketing}
                            value={rep.role || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "role",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={6}>
                          <Typography variant="body2">Email</Typography>
                          <TextField
                            fullWidth
                            size="small"
                            disabled={!isMarketing}
                            value={rep.email || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "email",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={6}>
                          <Typography variant="body2">Phone Number</Typography>
                          <TextField
                            fullWidth
                            disabled={!isMarketing}
                            size="small"
                            value={rep.contact_number || ""}
                            onChange={(e) =>
                              handleAddRepresentativeChange(
                                index,
                                "contact_number",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid size={2}>
                          <IconButton
                            title="Add Representative"
                            onClick={handleAddRepresentative}
                          >
                            <PersonAddAltIcon />
                          </IconButton>
                        </Grid>
                        <Grid size={10} alignContent="center">
                          <Typography variant="body2">
                            Click to add Representative/s.
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    <Typography>Select action</Typography>
                  </>
                )}
                <Grid
                  size={12}
                  container
                  justifyContent="flex-end"
                  sx={{ mt: 3 }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    sx={{ mr: 0.5 }}
                    onClick={() => setIsAddrep(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ mr: 0.5 }}
                    onClick={() => handleAddRep(attendeeData.attendee_id)}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Drawer>

      {/* Dialog for sending email */}
      <Dialog open={openDialog} fullWidth maxWidth={"lg"}>
        <DialogTitle>{"Send Email"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justifyContent={"center"} sx={{ mb: 2 }}>
            <Grid size={6} display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={action === "qr"}
                    onChange={() => handleActionChange("qr")}
                  />
                }
                label="QR Code"
              />
            </Grid>
            <Grid size={6} display="flex" alignItems="center">
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={action === "custom"} 
                    onChange={() => handleActionChange("custom")}
                  />
                }
                label="Custom Email"
              /> */}
            </Grid>
          </Grid>
          {action === "qr" ? (
            <>
              <Autocomplete
                disabled={selectAll}
                multiple
                options={confirmedAttendees}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.attendee_id
                } // Use unique id
                filterSelectedOptions
                onChange={handleSelect}
                renderInput={(params) => (
                  <TextField {...params} label="Attendee" placeholder="Name" />
                )}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll} // Corrected to handleSelectAll
                  />
                }
                label={
                  <Typography variant="overline">
                    Select All (Confirmed) Attendee
                  </Typography>
                }
              />
            </>
          ) : action === "custom" ? (
            <>
              {" "}
              <ReactQuill />
            </>
          ) : (
            "Select action"
          )}
          {selectedAttendees ? (
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              sx={{ mb: 2 }}
            >
              <Grid size={12}>
                <Divider />
              </Grid>
              {selectedAttendees.map((attd, index) => (
                <Paper key={index} sx={{ p: 2 }}>
                  <Typography>
                    {attd.name}{" "}
                    <Chip
                      size="small"
                      color={"default"}
                      label={attd.qr_count}
                    />
                  </Typography>
                  <RadioGroup
                    row
                    onChange={(event) =>
                      handleRadioChange(attd, event.target.value)
                    }
                  >
                    <FormControlLabel
                      value="Main Attendee"
                      control={<Radio />}
                      label="Main Attendee"
                    />
                    <FormControlLabel
                      value="Representative"
                      control={<Radio disabled={attd.isRepPresent} />}
                      label="Representative"
                    />
                  </RadioGroup>
                </Paper>
              ))}
            </Grid>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirmEmail} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Drawer for Adding a Company */}
      <Drawer open={openAddComp} onClose={() => closeDrawerComp()}>
        <Box
          className="thin-scrollbar"
          sx={{ width: { xs: 360, lg: 480, xl: 550 }, mt: 8, p: 2 }}
          role="presentation"
        >
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid size={12} display="flex" alignItems="center">
              <ApartmentIcon />
              <Typography variant="body" sx={{ ml: 1 }}>
                Add Company / Organization
              </Typography>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container rowSpacing={0.5} columnSpacing={1} sx={{ mt: 1 }}>
            <Grid size={12}>
              <Typography variant="body2">
                Company /Organization Name <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="company_name"
                value={compData.company_name}
                onChange={handleChangeCompany}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">
                Number of Seats<span style={asteriskStyle}>*</span>{" "}
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                type="number"
                size="small"
                name="alloted_seats"
                value={compData.alloted_seats}
                onChange={handleChangeCompany}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">
                Company /Organization Industry{" "}
                <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="nature_of_business"
                value={compData.nature_of_business}
                onChange={handleChangeCompany}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">
                Company / Org. Contact Number{" "}
                <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="company_contact_number"
                value={compData.company_contact_number}
                onChange={handleChangeCompany}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">
                Company / Org. Email <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="company_email"
                value={compData.company_email}
                onChange={handleChangeCompany}
              />
            </Grid>
            <Grid size={12} container justifyContent="flex-end" sx={{ mt: 3 }}>
              <Button
                variant="contained"
                size="small"
                color="error"
                sx={{ mr: 0.5 }}
                onClick={closeDrawerComp}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                sx={{ mr: 0.5 }}
                onClick={handleSubmitCompany}
              >
                submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </>
  );
}

export default EventDetails;
