import axios from "axios";

// Special Event
export const fetchEventDetails = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/forms/special-event-details?token=${token}`
    );
    return response.data.details[0];
  } catch (error) {
    throw error;
  }
};

// Booth Event
export const fetchBoothEventDetails = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/forms/booth-event-details?id=${id}`
    );
    return response.data.details;
  } catch (error) {
    console.log(error)
    throw new Error(error);
  }
};

export const registerBooth = async (data, token) => {
  try {
    // console.log(formData);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/forms/add-to-booth-event?id=${token}`,
      data
    );

    return response.data; // Return the response data for further use
  } catch (error) {
    console.log(error.response.data.message);
    throw new Error(error.response.data.error);
  }
};

export const registerSpecial = async (data, token) => {
  // Fix Express space issue: Replace spaces back to '+'
  token = token.replace(/ /g, "+");
  try {
    // console.log(formData);
    await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/forms/add-to-special-event?token=${token}`,
      data
    );
  } catch (error) {
    console.log(error)
    throw new Error(error.response.data.error);
  }
};
