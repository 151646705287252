import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import { ThemeProvider, CssBaseline } from "@mui/material";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTheme } from "@mui/material/styles";

// Main Theme
const mainTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  defaultMode: "light",
  colorSchemes: {
    dark: {
      palette: {
        background: {
          default: "#121212",
          paper: "#0A0A0A",
        },
        text: {
          primary: "#B6BEC9",
          secondary: "#B3B3B3",
        },
        outboundColor: {
          main: "#c27a9d",
          contrastText: "#000",
        },
        inboundColor: {
          main: "#d49074",
          contrastText: "#000",
        },
        textfieldColor: {
          main: "#B6BEC9",
        },
        lowColor: {
          main: "#cb994c",
          contrastText: "#000",
        },
        mediumColor: {
          main: "#d58543",
          contrastText: "#000",
        },
        highColor: {
          main: "#d1665a",
          contrastText: "#000",
        },
        cardColor: {
          main: "#121212",
        },
      },
    },
    light: {
      palette: {
        background: {
          default: "#F9F9FE",
          paper: "#EEEFEF",
        },
        text: {
          primary: "#000",
          secondary: "#555",
        },
        outboundColor: {
          main: "#69314c",
          contrastText: "#fff",
        },
        inboundColor: {
          main: "#603B2C",
          contrastText: "#fff",
        },
        negotiationColor: {
          main: "#492F64",
          contrastText: "#fff",
        },
        textfieldColor: {
          main: "#646464",
        },
        lowColor: {
          main: "#af884d",
          contrastText: "#fff",
        },
        mediumColor: {
          main: "#854C1D",
          contrastText: "#fff",
        },
        highColor: {
          main: "#8a2319",
          contrastText: "#fff",
        },
        cardColor: {
          main: "#F9F9FE",
        },
      },
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === "eventColor" && {
            backgroundColor: theme.palette.eventColor.main,
            color: theme.palette.eventColor.contrastText,
          }),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiInputBase-root": {
            color: theme.palette.textfieldColor.main, // Text color
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: "blue", // Default border color
          },
          "& .MuiInput-underline:hover:before": {
            borderBottomColor: "darkblue", // Hover border color
          },
        }),
      },
    },
  },
});

const mainTheme1 = createTheme({
  palette: {
    mode: "light", // Force light mode globally
    background: {
      default: "#F9F9FE",
      paper: "#EEEFEF",
    },
    text: {
      primary: "#000",
      secondary: "#555",
    },
    outboundColor: {
      main: "#69314c",
      contrastText: "#fff",
    },
    inboundColor: {
      main: "#603B2C",
      contrastText: "#fff",
    },
    negotiationColor: {
      main: "#492F64",
      contrastText: "#fff",
    },
    textfieldColor: {
      main: "#646464",
    },
    lowColor: {
      main: "#af884d",
      contrastText: "#fff",
    },
    mediumColor: {
      main: "#854C1D",
      contrastText: "#fff",
    },
    highColor: {
      main: "#8a2319",
      contrastText: "#fff",
    },
    cardColor: {
      main: "#F9F9FE",
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === "eventColor" && {
            backgroundColor: theme.palette.eventColor.main,
            color: theme.palette.eventColor.contrastText,
          }),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          "& .MuiInputBase-root": {
            color: theme.palette.textfieldColor.main, // Text color
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: "blue", // Default border color
          },
          "& .MuiInput-underline:hover:before": {
            borderBottomColor: "darkblue", // Hover border color
          },
        }),
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    {/* Wrapping the app with the maintheme to make the mainTheme globally accessible*/}
    <ThemeProvider theme={mainTheme1}>
      <CssBaseline />
      {/* Setting up the Router component from react-router-dom */}
      <QueryClientProvider client={queryClient}>
        <Router>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

console.log(
  `                                                                                               
@   @@@@   @@@@   &@@@   .@@@   .@@@    @@@    @@@    @@@,   @@@.   @@@&   @@@@   @@@@     
    @@@@   @@@@   &@@@   .@@@   .@@@    @@@    @@@    @@@,   @@@.   @@@&   @@@@   @@@@     
           @@@@   &...    ,,,    ,,,    ***    ***    ///.   (((.   ((((   ####   @@@@     
            @@@   &@@@    ,,,    ***    ***    ///    ///.   (((.   (##(   ####   @@@@     
                  &@@@   .@,,    ***    ***    ///    ///.   (((.   (##(   ####   ####     
                    @@   .@@@    ***    ***    ///    ///.   (((.   (##(   ####   ####     
                         .@@@   .@@*    ***    ///    ///.   (((.   (##(   ####   ####     
                            %   .@@@    ***    ///    ///.   (((.   (##(   ####   ####     
                                .@@@    @@@    ///    ///.   (((.   @@@&   @@@@   @@@@     
                                        @@@    ///    ///.   (((.   @@@&   @@@@   @@@@     
                                        *@@    @@@    ///.   (((.   ((((   ####   @@@@     
                                               @@@    @//.   (((.   (##(   ####   @@@@     
                                                (@    @@@,   (((.   (##(   ####   @@@@     
                                                      @@@,   @@/.   (##(   ####   @@@@     
                                                         ,   @@@.   (##(   ####   @@@@     
                                                             @@@.   @@@(   ####   @@@@     
                                                                    @@@&   ####   @@@@     
                                                                     @@&   @@@@   @@@@     
                                                                           @@@@   @@@@     
                                                                             @@   @@@@     
                                                                                  @@@@     
                                                                                     @     


GEOSPECTRUM Marketing Portal

Powered by Geospectrum Marketing Services

For more details about the development of the website and more information about
Geospectrum Marketing Services, please visit https://www.geospectrum.com.ph/ 

ASCII art made with https://manytools.org/hacker-tools/convert-images-to-ascii-art/.

  `
);
