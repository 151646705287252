import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import { Divider, Typography, TextField, Button } from "@mui/material";
import moment from "moment";
import { Toast } from "../../utils/Alert";
import { useNavigate } from "react-router-dom";

// Icons
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventIcon from "@mui/icons-material/Event";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  fetchBoothEventDetails,
  registerBooth,
} from "../../services/registrationAPi";

function BoothForm() {
  const query = new URLSearchParams(window.location.search);
  const { token } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const notification = (icon, message) => {
    Toast.fire({
      icon: icon,
      title: message,
    });
  };
  // Fetch company details
  const { isLoading, data: event } = useQuery({
    queryKey: ["eventBtInfo"],
    queryFn: () => fetchBoothEventDetails(token),
  });

  // Textfield Style
  const textFieldStyles = {
    "& .MuiInputBase-input": {
      color: "black", // Change text color
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#838383", // Default border color
      },
      "&:hover fieldset": {
        borderColor: "#666", // Border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1976d2", // Border color when focused
      },
    },
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAttendeeData((prevState) => ({
      ...prevState,
      [name]: value, 
    }));
  };

  // Attendee data
  const [attendeeData, setAttendeeData] = useState({
    // state for attendee data
    name: "",
    vip: false,
    email: "",
    contact_number: "",
    status: "",
    level_of_interest: "",
    job_title: "",
    company_name: "",
    company_email: "",
    company_contact_number: "",
    nature_of_business: "",
    notes: "",
    representatives: [],
  });

  // Adding Attendee
  const addmutation = useMutation({
    mutationFn: (formData) => registerBooth(formData, token),
    onSuccess: () => {
      navigate("/success-registration"); // Redirect to Thankyoupage
      queryClient.invalidateQueries(["comp_Visitors", "attendees"]);
    },
  });

  const handleSubmitAttendee = () => {
    // Check if any field in formData is empty
    if (
      attendeeData.name === "" ||
      attendeeData.email === "" ||
      attendeeData.contact_number === "" ||
      attendeeData.job_title === "" ||
      attendeeData.company_name === ""
    ) {
      notification("error", "Fill in required fields.");
      return;
    }
    addmutation.mutate(attendeeData);
  };

  //Loading State
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img
          src={`${process.env.REACT_APP_BACKEND_DOMAIN}/Event-Imgs/logo_gif.gif`}
          alt="Loading..."
        />
      </div>
    );
  }
  return (
    <>
      <Box
        component="section"
        sx={{
          p: { xs: 2, md: 5, lg: 20, xl: 25 },
          pt: { xs: 2, md: 4, lg: 8, xl: 8 },
          pb: { xs: 2, md: 2, lg: 5, xl: 5 },
          backgroundColor: "#f5f5f5",
          color: "black",
        }}
      >
        <Grid
          container
          direction="row"
          rowSpacing={1}
          columnSpacing={2}
          sx={{
            p: 2,
            pl: { xs: 1, md: 1, lg: 10, xl: 10 },
            pr: { xs: 1, md: 1, lg: 10, xl: 10 },
          }}
        >
          <Grid size={12} sx={{ textAlign: "flex-start", pb: 2 }}>
            <Box
              component="section"
              sx={{
                p: 2,
                border: 1,
                borderColor: "#d7d7d7",
                borderRadius: 1,
                backgroundColor: "#013976",
              }}
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={1}
                sx={{ p: 1, pl: 2, pr: 2 }}
              >
                <Grid size={{ xs: 12, md: 9, lg: 9, xl: 9 }}>
                  <Typography variant="h4" sx={{ color: "white" }}>
                    Registration Form
                  </Typography>
                </Grid>
                <Grid
                  item
                  size={{ xs: 12, md: 3, lg: 3, xl: 3 }}
                  container
                  alignItems="center"
                >
                  <Box
                    component="img"
                    src={`${process.env.REACT_APP_BACKEND_DOMAIN}/Event-Imgs/logo-white-gms.png`}
                    sx={{
                      width: "100%",
                      maxWidth: 300,
                      height: "auto",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid
            item
            size={{ xs: 12, md: 12, lg: 6, xl: 6 }}
            sx={{ textAlign: "center" }}
          >
            <Box
              component="img"
              src={`${process.env.REACT_APP_BACKEND_DOMAIN}/Event-Imgs/defaultEvent_wallpaper.jpg`}
              sx={{
                width: "100%",
                maxWidth: 500,
                height: "auto",
                borderRadius: 2,
              }}
            />
          </Grid>
          <Grid
            size={{ xs: 12, md: 12, lg: 6, xl: 6 }}
            sx={{
              textAlign: "flex-start",
              pl: { xs: 1, md: 1, lg: 5, xl: 5 },
              pt: 5,
            }}
          >
            <Typography variant="h5" sx={{ pb: 2 }}>
              {event.name}
            </Typography>
            <Typography variant="body">{event.description}</Typography>
            <Typography variant="body2" sx={{ fontStyle: "italic", pt: 5 }}>
              Your privacy matters to us. The information you provide will only
              be used for event-related purposes in accordance with our Privacy
              Policy.
            </Typography>
          </Grid>
          <Grid size={12} sx={{ textAlign: "flex-start", pb: 2 }}>
            <Box
              component="section"
              sx={{
                p: 2,
                border: 1,
                borderColor: "#d7d7d7",
                borderRadius: 1,
                backgroundColor: "#e7e7e7",
              }}
            >
              <Grid
                container
                rowSpacing={1}
                columnSpacing={1}
                sx={{ p: 1, pl: 2, pr: 2 }}
              >
                <Grid size={12} sx={{ textAlign: "flex-start" }}>
                  <Typography variant="h6">Event Details</Typography>
                </Grid>
                <Grid size={12} sx={{ pb: 1 }}>
                  <Divider />
                </Grid>
                <Grid size={6}>
                  <Typography variant="body1" component="div">
                    <Box display="flex" alignItems="center">
                      <EventIcon style={{ marginRight: 8 }} />
                      {moment(event.start_date).format("ll")} -{" "}
                      {moment(event.end_date).format("ll")}
                    </Box>
                  </Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body1" component="div">
                    <Box display="flex" alignItems="center">
                      <AccessTimeIcon style={{ marginRight: 8 }} />
                      {moment(event.start_date).format("LT")}-{" "}
                      {moment(event.end_date).format("LT")}
                    </Box>
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <Typography variant="body1" component="div">
                    <Box display="flex" alignItems="center">
                      <PinDropIcon style={{ marginRight: 8 }} />
                      {event.location}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={4}
          columnSpacing={2}
          sx={{
            pl: { xs: 1, md: 1, lg: 10, xl: 10 },
            pr: { xs: 1, md: 1, lg: 10, xl: 10 },
          }}
        >
          <Grid size={{ xs: 12, md: 12, lg: 6, xl: 6 }}>
            <TextField
              fullWidth
              size="small"
              name="name"
              label="Name"
              required
              sx={textFieldStyles}
              onChange={handleChange}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 12, lg: 6, xl: 6 }}>
            <TextField
              fullWidth
              size="small"
              name="job_title"
              label="Job Title / Position"
              sx={textFieldStyles}
              required
              onChange={handleChange}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 12, lg: 6, xl: 6 }}>
            <TextField
              fullWidth
              size="small"
              name="email"
              label="Email"
              required
              sx={textFieldStyles}
              onChange={handleChange}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 12, lg: 6, xl: 6 }}>
            <TextField
              fullWidth
              size="small"
              required
              name="contact_number"
              label="Phone Number"
              sx={textFieldStyles}
              onChange={handleChange}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 12, lg: 6, xl: 6 }}>
            <TextField
              fullWidth
              size="small"
              name="company_name"
              required
              label="Agency / Company / Organization / Institution"
              sx={textFieldStyles}
              onChange={handleChange}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 12, lg: 6, xl: 6 }}>
            <TextField
              fullWidth
              size="small"
              name="company_email"
              label="Agency / Company Email"
              sx={textFieldStyles}
              onChange={handleChange}
            />
          </Grid>

          <Grid size={12} sx={{ textAlign: "center", pt: 1 }}>
            <Button variant="contained" onClick={handleSubmitAttendee}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default BoothForm;
