import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Divider,
  Button,
  Drawer,
} from "@mui/material";
import { useParams } from "react-router-dom";

import {
  viewDetails,
  deleteCompany,
  editCompany,
} from "../../services/companyAPI"; //API call
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Toast, confirmDialog } from "../../utils/Alert";
import CircularProgress from "@mui/material/CircularProgress";

// icons
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";

function CircularProgressWithLabel({ allotedSeats, remainingSeats }) {
  const percentage =
    allotedSeats > 0
      ? ((allotedSeats - remainingSeats) / allotedSeats) * 100
      : 0;
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" value={percentage} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary", pt: 2 }}
        >
          {remainingSeats + "/" + allotedSeats}
        </Typography>
      </Box>
    </Box>
  );
}

function EventCompany({ filteredData, clickCompanyFunction, isMarketing }) {
  const { id, event } = useParams();
  const asteriskStyle = { color: "#c20a0a" };
  const queryClient = useQueryClient();

  // Company data
  const [compData, setCompData] = useState({
    // state for attendee data
    company_name: "",
    alloted_seats: "",
    company_email: "",
    company_contact_number: "",
    nature_of_business: "",
  });

  // Edit Company Drawer
  const [openEditComp, setOpenEditComp] = React.useState(false);
  const toggleDrawerEditComp = (newOpen, companyId) => async () => {
    setOpenEditComp(newOpen);
    const selectedCompany = await viewDetails(id, companyId);
    setCompData({
      company_name: selectedCompany.company_name || "",
      alloted_seats: selectedCompany.company_alloted_seats || "",
      company_email: selectedCompany.company_email || "",
      company_contact_number: selectedCompany.company_contact_number || "",
      nature_of_business: selectedCompany.company_nature_of_business || "",
      orgId: selectedCompany.company_id || "",
    });
  };

  const closeDrawerEditComp = () => {
    setOpenEditComp(false);
    setCompData([]);
  };

  //Edit Company
  const editCompanyemutation = useMutation({
    mutationFn: (data) => editCompany(id, compData.orgId, data),
    onSuccess: () => {
      closeDrawerEditComp();
      queryClient.invalidateQueries(["companies"]);
      notification("success", "Company updated!");
    },
    onError: (error) => {
      console.error(error);
      notification("error", error);
    },
  });

  const EditCompany = (attendee_ID) => {
    // Check if any field in formData is empty
    if (
      compData.company_name === "" ||
      compData.alloted_seats === "" ||
      compData.company_email === "" ||
      compData.nature_of_business === "" ||
      compData.company_contact_number === ""
    ) {
      notification("error", "Fill in required fields.");
      return;
    }
    editCompanyemutation.mutate(compData);
  };

  const handleEditCompany = (event) => {
    const { name, value } = event.target;

    setCompData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const notification = (icon, message) => {
    Toast.fire({
      icon: icon,
      title: message,
    });
  };

  // Delete Company______________________
  const deleteCompanyMutation = useMutation({
    mutationFn: (orgId) => deleteCompany(id, orgId),
    onSuccess: () => {
      closeDrawerEditComp();
      queryClient.invalidateQueries(["companies"]);
      notification("success", "Company successfully deleted");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while deleting company.");
    },
  });

  const handleDeleteCompany = async (orgId) => {
    const userConfirmed = await confirmDialog({
      title: "Delete company?",
      text: "This action cannot be undone.",
    });
    if (userConfirmed) {
      deleteCompanyMutation.mutate(orgId);
    }
  };

  // Click function on certain coompany, it passes the company details
  const handleClickCompany = (tab, cname, cid) => {
    clickCompanyFunction(tab, cname, cid);
  };

  // small square color
  const colors = [
    "#3B5F72",
    "#112959",
    "#8B3A3A",
    "#556B2F",
    "#BF5700",
    "#65000B",
    "#2C3E50",
  ];
  return (
    <>
      {/* Main */}
      <Grid container size={12} spacing={2}>
        {filteredData.length != 0 ? (
          <>
            {filteredData.map((cmpny, index) => (
              <Grid
                key={cmpny.company_id}
                size={{ xs: 12, sm: 6, lg: 4, xl: 4 }}
              >
                <Card sx={{ maxWidth: 435, position: "relative" }}>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundColor: colors[index % colors.length], // Change color as needed
                      position: "absolute",
                      top: 8,
                      right: 8,
                      borderRadius: 1,
                    }}
                  />

                  <Box
                    sx={{
                      cursor: "pointer",
                      transition: "transform 0.2s, box-shadow 0.2s",
                      "&:hover": {
                        transform: "scale(1.02)",
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                      },
                    }}
                  >
                    <CardHeader
                      sx={{ height: 75 }}
                      onClick={() =>
                        handleClickCompany(
                          2,
                          cmpny.company_name,
                          cmpny.company_id
                        )
                      }
                      title={
                        <Typography
                          variant="body"
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                          }}
                        >
                          {cmpny.company_name}
                          {/* <Chip
                            sx={{ ml: 1, fontWeight: "regular" }}
                            size="small"
                            color="default"
                            label={cmpny.company_alloted_seats}
                          /> */}
                        </Typography>
                      } // subheader={
                      //   <Typography
                      //     variant="body2"
                      //     sx={{ fontSize: "0.875rem", fontStyle: "italic" }}
                      //   >
                      //     {cmpny.company_remaining_seats} seats remaining
                      //   </Typography>
                      // }
                    />

                    <CardContent
                      sx={{
                        height: 60,
                        p: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <CircularProgressWithLabel
                        allotedSeats={cmpny.company_alloted_seats}
                        remainingSeats={cmpny.company_remaining_seats}
                      />
                      <IconButton
                        title="Edit Company"
                        size="large"
                        onClick={toggleDrawerEditComp(true, cmpny.company_id)}
                        sx={{ p: 0, ml: 2 }}
                      >
                        {isMarketing ? (
                          <ModeEditOutlineIcon fontSize="inherit" />
                        ) : (
                          <VisibilityIcon
                            fontSize="inherit"
                            sx={{ mt: 1, mr: 1 }}
                          />
                        )}
                      </IconButton>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            ))}
          </>
        ) : (
          "No company"
        )}
      </Grid>

      {/* Drawer for Editing Company Details*/}
      <Drawer open={openEditComp} onClose={() => closeDrawerEditComp()}>
        <Box
          className="thin-scrollbar"
          sx={{ width: { xs: 360, lg: 480, xl: 550 }, mt: 8, p: 2 }}
          role="presentation"
        >
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid size={12} display="flex" alignItems="center">
              <ApartmentIcon />
              <Typography variant="body" sx={{ ml: 1 }}>
                Add Company / Organization
              </Typography>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container rowSpacing={0.5} columnSpacing={1} sx={{ mt: 1 }}>
            <Grid size={12}>
              <Typography variant="body2">
                Company /Organization Name <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="company_name"
                value={compData.company_name || ""}
                disabled={!isMarketing}
                onChange={handleEditCompany}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">
                Number of Seats<span style={asteriskStyle}>*</span>{" "}
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                type="number"
                size="small"
                disabled={!isMarketing}
                name="alloted_seats"
                value={compData.alloted_seats || ""}
                onChange={handleEditCompany}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">
                Company /Organization Industry{" "}
                <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                disabled={!isMarketing}
                size="small"
                name="nature_of_business"
                value={compData.nature_of_business || ""}
                onChange={handleEditCompany}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">
                Company / Org. Contact Number{" "}
                <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                disabled={!isMarketing}
                size="small"
                name="company_contact_number"
                value={compData.company_contact_number || ""}
                onChange={handleEditCompany}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">
                Company / Org. Email <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                disabled={!isMarketing}
                name="company_email"
                value={compData.company_email || ""}
                onChange={handleEditCompany}
              />
            </Grid>
            {isMarketing && (
              <Grid
                size={12}
                container
                justifyContent="flex-end"
                sx={{ mt: 3 }}
              >
                <IconButton
                  title="Delete"
                  onClick={() => handleDeleteCompany(compData.orgId)}
                >
                  <DeleteForeverIcon />
                </IconButton>
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  sx={{ mr: 0.5 }}
                  onClick={closeDrawerEditComp}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  sx={{ mr: 0.5 }}
                  onClick={EditCompany}
                >
                  submit
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Drawer>
    </>
  );
}

export default EventCompany;
