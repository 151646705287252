import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Divider,
  Drawer,
  IconButton,
  Autocomplete,
  Avatar,
  Chip,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  fetchLeads,
  addleads,
  getLeadDetails,
  updateLead,
  updateChampion,
  updateContact,
  fetchEmployees,
  addItem,
  updateActionItem,
} from "../../services/leadsAPI"; //API call
import { filterAttendees } from "../../utils/Filter";
import { Toast, confirmDialog } from "../../utils/Alert";
import moment from "moment";

// icons
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import SearchIcon from "@mui/icons-material/Search";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";

// Table Columns
const columns = [
  {
    id: "icon",
    minWidth: 50,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  { id: "status", label: "Status" },
  { id: "project_name", label: "Project Name", minWidth: 200 },
  {
    id: "implementing_agency",
    label: "Implementing Agency",
    minWidth: 200,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "company_name",
    label: "Company",
    minWidth: 150,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "level_of_priority",
    label: "Priority",
    minWidth: 70,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "company_contact_number",
    label: "Phone",
    minWidth: 120,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "company_email",
    label: "Email",
    minWidth: 170,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "contact_person",
    label: "Contact Person",
    minWidth: 150,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "champion_details",
    label: "Champion",
    minWidth: 210,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "account_owner",
    label: "Account Owner",
    minWidth: 170,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
];
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
// Products list
const products = [
  "Product 1",
  "Product 2",
  "Product 3",
  "Product 4",
  "Product 5",
];

// Industry list
const industry = [
  "Aerospace and Defense",
  "Agriculture and Farming",
  "Construction",
  "Education",
  "Energy",
  "Entertainment and Media",
  "Financial Services",
  "Food and Beverage",
  "Healthcare and Pharmaceuticals",
  "Hospitality and Tourism",
  "Information Technology",
  "Manufacturing",
  "Mining and Natural Resources",
  "Non-Profit and Social Enterprises",
  "Real Estate",
  "Telecommunications",
  "Transportation and Logistics",
];

const typographyStyles = {
  // fontWeight: "bold",
  mt: 0.5,
};

function Project({ isMarketing, user }) {
  const queryClient = useQueryClient();

  // Handle change page
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const notification = (icon, message) => {
    Toast.fire({
      icon: icon,
      title: message,
    });
  };

  // Fetch Leads
  const [searchTerm, setSearchTerm] = useState("");
  const { isLoading, data: leads } = useQuery({
    queryKey: ["leads"], // Query key for caching and invalidation
    queryFn: () => fetchLeads(), // Function to fetch data
  });

  // Adding Leads__________________________________
  // Add leads Drawer
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  //closes leads drawer and clear event data
  const closeDrawer = () => {
    setOpen(false);
    setLeadData({
      project_name: "",
      company_name: "",
      implementing_agency: "",
      company_email: "",
      company_contact_number: "",
      estimated_value: "",
      nature_of_business: "",
      interaction_logs: [],
      action_items: [],
      status: "",
      level_of_priority: "",
      product_interested_in: [],
      source: "",
      contact_person: [],
      champion_details: [],
    });
  };

  // Lead data
  const [leadData, setLeadData] = useState({
    // state for attendee data
    project_name: "",
    company_name: "",
    implementing_agency: "",
    company_email: "",
    company_contact_number: "",
    estimated_value: "",
    nature_of_business: "",
    interaction_logs: [],
    action_items: [],
    status: "",
    level_of_priority: "",
    product_interested_in: [],
    source: "",
    contact_person: [],
    champion_details: [],
    user: {},
  });

  // Format textfield input
  const formatNumber = (value) => {
    if (!value) return value;
    // Remove non-numeric characters except dot
    const cleanedValue = value.replace(/[^0-9.]/g, "");
    const [whole, decimal] = cleanedValue.split(".");
    const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return decimal !== undefined
      ? `${formattedWhole}.${decimal.slice(0, 2)}`
      : formattedWhole;
  };

  // Textfield input function
  const handleChange = (lead) => {
    const { name, value } = lead.target;
    const formattedValue =
      name === "estimated_value" ? formatNumber(value) : value;

    setLeadData((prevState) => ({
      ...prevState,
      [name]: formattedValue,
    }));
  };

  const handleAddChampion = () => {
    // Add a new champion
    setLeadData((prevState) => ({
      ...prevState,
      champion_details: [
        ...prevState.champion_details,
        { name: "", email: "", phone_number: "", job_title: "" },
      ],
    }));
  };

  const handleChampionChange = (index, field, value) => {
    // Update the specific champion's field
    const updatedChaps = [...leadData.champion_details];
    updatedChaps[index][field] = value;
    setLeadData((prevState) => ({
      ...prevState,
      champion_details: updatedChaps,
    }));
  };

  const handleAddContact = () => {
    // Add a new champion
    setLeadData((prevState) => ({
      ...prevState,
      contact_person: [
        ...prevState.contact_person,
        { name: "", email: "", phone_number: "", job_title: "" },
      ],
    }));
  };

  const handleContactChange = (index, field, value) => {
    // Update the specific champion's field
    const updatedContact = [...leadData.contact_person];
    updatedContact[index][field] = value;
    setLeadData((prevState) => ({
      ...prevState,
      contact_person: updatedContact,
    }));
  };

  const handleAddLog = () => {
    // Add a new champion
    setLeadData((prevState) => ({
      ...prevState,
      interaction_logs: [...prevState.interaction_logs, { details: "" }],
    }));
  };

  const handleLogChange = (index, field, value) => {
    // Update the specific champion's field
    const updatedlog = [...leadData.interaction_logs];
    updatedlog[index][field] = value;
    setLeadData((prevState) => ({
      ...prevState,
      interaction_logs: updatedlog,
    }));
  };

  // Autocomplete product interested
  const handleSelect = (event, newValue) => {
    setLeadData((prevState) => ({
      ...prevState,
      product_interested_in: newValue, // Update the product_interested_in field
    }));
  };

  // Autocomplete product interested
  const handleSelectInterested = (event, newValue) => {
    setLeadData((prevState) => ({
      ...prevState,
      nature_of_business: newValue, // Update the nature_of_business field
    }));
  };

  // Adding Leads
  const addmutation = useMutation({
    mutationFn: (formData) => addleads(formData),
    onSuccess: () => {
      closeDrawer();
      queryClient.invalidateQueries(["leads"]);
      notification("success", "Project / Lead added!");
    },
    onError: (error) => {
      console.log(error);
      notification("error", error.response.data.error);
    },
  });

  const handleSubmitLead = () => {
    // Check if any field in formData is empty
    if (
      leadData.project_name === "" ||
      leadData.company_name === "" ||
      leadData.company_email === "" ||
      leadData.company_contact_number === "" ||
      leadData.estimated_value === ""
    ) {
      notification("error", "Fill in required fields.");
      return;
    }
    // Update leadData with user information
    const updatedLeadData = {
      ...leadData,
      user: user, // Attach user data before submission
    };

    addmutation.mutate(updatedLeadData);
  };

  // Editing Leads__________________________________
  // Edit leads Drawer
  const [openEdit, setOpenEdit] = React.useState(false);

  //closes leads drawer and clear event data
  const closeDrawerEdit = () => {
    setOpenEdit(false);
    setLeadData({
      project_name: "",
      company_name: "",
      implementing_agency: "",
      company_email: "",
      company_contact_number: "",
      estimated_value: "",
      account_owner: "",
      nature_of_business: "",
      interaction_logs: [],
      action_items: [],
      status: "",
      level_of_priority: "",
      product_interested_in: [],
      source: "",
      contact_person: [],
      champion_details: [],
      estimated_value: "",
    });
  };
  const [leadID, setLeadID] = useState(); // selected Lead ID
  const [originalProjectName, setOriginalProjectName] = useState(); // original project name for reference
  const editLead = async (lead_ID) => {
    setOpenEdit(true);
    setLeadID(lead_ID);
    const selected_lead = await getLeadDetails(lead_ID);
    setLeadData(selected_lead);
    setOriginalProjectName(selected_lead.project_name);
  };

  // Update Mutation
  const updateLeadMutation = useMutation({
    mutationFn: () => updateLead(leadID, leadData),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["leads"]);
      notification("success", "Lead successfully updated");
    },
    onError: (error) => {
      console.log(error);
      notification("error", error.response.data.error);
    },
  });

  const handleUpdateLead = async () => {
    if (
      leadData.project_name === "" ||
      leadData.company_name === "" ||
      leadData.company_email === "" ||
      leadData.company_contact_number === "" ||
      leadData.estimated_value === ""
    ) {
      notification("error", "Fill in required fields.");
      return;
    }

    // Check if project_name is unchanged
    if (leadData.project_name === originalProjectName) {
      setLeadData((prevState) => {
        const { project_name, ...rest } = prevState; // exclude project_name
        return rest; // Return the rest of the object without project_name
      });
    }
    console.log(leadData);
    updateLeadMutation.mutate();
  };

  // Delete Attendee (set hidden to true)______________________
  const deleteLeadMutation = useMutation({
    mutationFn: (data) => updateLead(leadID, data),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["leads"]);
      notification("success", "Lead successfully deleted");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDeleteLead = async () => {
    const userConfirmed = await confirmDialog({
      title: "Delete Lead?",
      text: "This action cannot be undone.",
    });
    if (userConfirmed) {
      deleteLeadMutation.mutate({ hidden: true });
    }
  };

  // Delete Champion______________________
  const deleteChampMutation = useMutation({
    mutationFn: ({ leadID, champID }) =>
      updateChampion(leadID, champID, { hidden: true }),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["leads"]);
      notification("success", "Champion successfully deleted");
    },
  });

  const handleDeleteChamp = async (leadID, champID) => {
    const userConfirmed = await confirmDialog({
      title: "Delete Champion?",
      text: "This action cannot be undone.",
    });
    if (userConfirmed) {
      deleteChampMutation.mutate({ leadID, champID });
    }
  };

  // Delete Contact______________________
  const deleteContactMutation = useMutation({
    mutationFn: ({ leadID, contactId }) =>
      updateContact(leadID, contactId, { hidden: true }),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["leads"]);
      notification("success", "Contact Person successfully deleted");
    },
  });

  const handleDeleteContact = async (leadID, contactId) => {
    const userConfirmed = await confirmDialog({
      title: "Delete Contact Person?",
      text: "This action cannot be undone.",
    });
    if (userConfirmed) {
      deleteContactMutation.mutate({ leadID, contactId });
    }
  };

  // Fetch Employee listt
  const { data: employees } = useQuery({
    queryKey: ["employees"], // Query key for caching and invalidation
    queryFn: () => fetchEmployees(), // Function to fetch data
  });

  // Dialog (Action Items)
  const [details, setDetails] = useState("");
  const [deadline, setDeadline] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
    setDetails("");
    setDeadline("");
    setSelectedEmployees([]);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  // Autocomplete Section
  const handleSelectEmployee = (event, newValue) => {
    const employeeData = newValue.map((emp) => ({
      lastname: emp.lastname + " " + emp.firstname,
      email: emp.email,
    }));
    setSelectedEmployees(employeeData);
  };

  // Add Action Item
  const saveActionItem = () => {
    if (deadline === "" || details === "") {
      notification("error", "Fill in required fields.");
      return;
    }
    const payload = {
      deadline,
      details,
      employees_involved: selectedEmployees,
    };
    addActionItemMutation.mutate(payload);
  };

  const addActionItemMutation = useMutation({
    mutationFn: (formData) => addItem(formData, leadID),
    onSuccess: async () => {
      queryClient.invalidateQueries(["leads"]);
      notification("success", "Action Item added!");
      const selected_lead = await getLeadDetails(leadID); //refecth and clear data
      setLeadData(selected_lead);
      setSelectedEmployees([]);
      setDetails("");
      setDeadline("");
    },
    onError: (error) => {
      notification("error", error.response.data.error);
    },
  });

  // Delete/Hide Action Item______________________
  const deleteItemMutation = useMutation({
    mutationFn: (itemID) => updateActionItem(leadID, itemID, { hidden: true }),
    onSuccess: async () => {
      queryClient.invalidateQueries(["leads"]);
      notification("success", "Action Item successfully deleted");
      const selected_lead = await getLeadDetails(leadID); //refecth and clear data
      setLeadData(selected_lead);
    },
    onError: (error) => {
      console.log(error);
      notification("error", error.response.data.error);
    },
  });

  const handleDeleteItem = async (itemID) => {
    const userConfirmed = await confirmDialog({
      title: "Delete Action Item?",
      text: "This action cannot be undone.",
    });
    if (userConfirmed) {
      deleteItemMutation.mutate(itemID);
    }
  };

  // Update Action Item______________________
  const updateItemMutation = useMutation({
    mutationFn: (itemID) => updateActionItem(leadID, itemID, { is_done: true }),
    onSuccess: async () => {
      queryClient.invalidateQueries(["leads"]);
      notification("success", "Action Item successfully updated");
      const selected_lead = await getLeadDetails(leadID); //refecth and clear data
      setLeadData(selected_lead);
    },
    onError: (error) => {
      console.log(error);
      notification("error", error.response.data.error);
    },
  });

  const handleUpdateItem = async (itemID) => {
    const userConfirmed = await confirmDialog({
      title: "Action Item is Done?",
      text: "This action cannot be undone.",
    });
    if (userConfirmed) {
      updateItemMutation.mutate(itemID);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openpop = Boolean(anchorEl);
  const asteriskStyle = { color: "#c20a0a" };
  if (isLoading || isLoading) return <div>Loading...</div>; //loading state
  const filteredData = filterAttendees(leads, searchTerm);
  return (
    <>
      {/* Main */}
      <Box sx={{ flexGrow: 1, ml: 2 }}>
        <Typography sx={{ mb: 2 }} variant="h5">
          Project / Leads
        </Typography>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, lg: 8 }}>
            {isMarketing && (
              <Button
                variant="contained"
                startIcon={<PeopleOutlineIcon />}
                size="small"
                onClick={toggleDrawer(true)}
              >
                Add prospect
              </Button>
            )}
          </Grid>
          <Grid size={{ xs: 12, lg: 4 }} container justifyContent="flex-end">
            <IconButton
              title="Download List"
              size="large"
              // onClick={toggleDrawer(true)}
              sx={{ p: 0, ml: 3 }}
            >
              <SimCardDownloadOutlinedIcon fontSize="inherit" />
            </IconButton>
            <TextField
              label="Search"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
          <Grid size={12}>
            <TableContainer className="thin-scrollbar">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                        sx={{
                          display: column.display || "table-cell",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    // Display a loading indicator properly wrapped in table elements
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow
                          key={row._id}
                          onClick={() => editLead(row._id)}
                          className="table-row"
                        >
                          <TableCell
                            align="left"
                            sx={{
                              display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              },
                            }}
                          >
                            <Avatar>
                              {row.project_name
                                ? row.project_name.charAt(0).toUpperCase()
                                : "?"}
                            </Avatar>
                          </TableCell>
                          <TableCell align="left">
                            <Chip
                              size="small"
                              color={
                                row.status === "Outbound"
                                  ? "outboundColor"
                                  : row.status === "Inbound"
                                    ? "inboundColor"
                                    : row.status === "Qualified"
                                      ? "warning"
                                      : row.status === "Proposal"
                                        ? "primary"
                                        : row.status === "Closed"
                                          ? "success"
                                          : row.status === "Lost"
                                            ? "error"
                                            : row.status === "Negotiation"
                                              ? "negotiationColor"
                                              : row.status === "Backburner"
                                                ? "secondary"
                                                : "default" // or any other default color
                              }
                              label={row.status}
                            />
                          </TableCell>
                          <TableCell align="left">{row.project_name}</TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              },
                            }}
                          >
                            {row.implementing_agency}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              },
                            }}
                          >
                            {row.company_name}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              },
                            }}
                          >
                            <Chip
                              size="small"
                              color={
                                row.level_of_priority === "Low"
                                  ? "lowColor"
                                  : row.level_of_priority === "Medium"
                                    ? "mediumColor"
                                    : "highColor"
                              }
                              label={row.level_of_priority}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              },
                            }}
                          >
                            {row.company_contact_number}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              },
                            }}
                          >
                            {row.company_email}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              },
                            }}
                          >
                            {row.contact_person.map((champ, index) => (
                              <React.Fragment key={index}>
                                <Typography variant="body2">
                                  {champ.name}
                                </Typography>
                                <Typography variant="caption">
                                  {champ.job_title}
                                </Typography>
                              </React.Fragment>
                            ))}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              },
                            }}
                          >
                            {row.champion_details.map((champ, index) => (
                              <React.Fragment key={index}>
                                <Typography variant="body2">
                                  {champ.name}
                                </Typography>
                                <Typography variant="caption">
                                  {champ.job_title}
                                </Typography>
                              </React.Fragment>
                            ))}
                          </TableCell>

                          <TableCell
                            align="left"
                            sx={{
                              display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              },
                            }}
                          >
                            {row.account_owner}
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 30]}
              component="div"
              count={leads.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Drawer for Adding Project/Leads */}
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Box
          className="thin-scrollbar"
          sx={{ width: { xs: "auto", lg: 520, xl: 580 }, mt: 8, p: 2 }}
          role="presentation"
        >
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid size={12} display="flex" alignItems="center">
              <PeopleOutlineIcon />
              <Typography variant="body" sx={{ ml: 1 }}>
                Add Leads
              </Typography>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container spacing={0.5} columnSpacing={2} sx={{ mt: 2 }}>
            <Grid size={12}>
              <Typography variant="body2">
                Project Name <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="project_name"
                value={leadData.project_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">
                Company / Organization <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="company_name"
                value={leadData.company_name}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">Implementing Agency</Typography>
            </Grid>

            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="implementing_agency"
                value={leadData.implementing_agency}
                onChange={handleChange}
              />
            </Grid>
            {/* <Grid size={6}>
              <Typography variant="body2">Start Date</Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">End Date</Typography>
            </Grid>
            <Grid size={6}>
              <TextField fullWidth size="small" name="start_date" />
            </Grid>
            <Grid size={6}>
              <TextField fullWidth size="small" name="end_date" />
            </Grid> */}
            <Grid size={12}>
              <Typography variant="body2">
                Estimated Value <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="estimated_value"
                value={leadData.estimated_value}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">Status</Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">Priority</Typography>
            </Grid>
            <Grid size={6}>
              <Select
                size="small"
                fullWidth
                name="status"
                value={leadData.status}
                onChange={handleChange}
              >
                <MenuItem value="Outbound">Outbound</MenuItem>
                <MenuItem value="Inbound">Inbound</MenuItem>
                <MenuItem value="Qualified">Qualified</MenuItem>
                <MenuItem value="Proposal">Proposal</MenuItem>
                <MenuItem value="Negotiation">Negotiation</MenuItem>
                <MenuItem value="Closed">Closed</MenuItem>
                <MenuItem value="Lost">Lost</MenuItem>
                <MenuItem value="Backburner">Backburner</MenuItem>
              </Select>
            </Grid>
            <Grid size={6}>
              <Select
                size="small"
                fullWidth
                name="level_of_priority"
                value={leadData.level_of_priority}
                onChange={handleChange}
              >
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="High">High</MenuItem>
              </Select>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">
                Company / Org. Email <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">
                Company / Org. Phone <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={6}>
              <TextField
                fullWidth
                size="small"
                name="company_email"
                value={leadData.company_email}
                onChange={handleChange}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                fullWidth
                type="text"
                size="small"
                name="company_contact_number"
                value={leadData.company_contact_number}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                  handleChange({
                    target: { name: e.target.name, value: numericValue },
                  });
                }}
              />
            </Grid>

            <Grid size={12}>
              <Typography variant="body2">Industry</Typography>
            </Grid>
            <Grid size={12}>
              <Autocomplete
                options={industry}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                onChange={handleSelectInterested}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">Product Interested In</Typography>
            </Grid>
            <Grid size={12}>
              <Autocomplete
                multiple
                options={products}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                onChange={handleSelect}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">Source</Typography>
            </Grid>
            <Grid size={12} sx={{ mb: 2 }}>
              <TextField
                fullWidth
                size="small"
                name="source"
                value={leadData.source}
                onChange={handleChange}
              />
            </Grid>
            {/* <Grid size={9} textAlign={"right"}>
              Upload Logo <br />
              <Typography variant="body2">"No Image Selected"</Typography>
            </Grid>
            <Grid size={3}>
              <input
                type="file"
                accept="image/*"
                name="image"
                style={{ display: "none" }} // Hide the actual file input
              />
              <IconButton>
                <FileUploadIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Grid> */}
            {/* Champion */}
            <Grid size={12}>
              <Typography variant="body">Champion</Typography>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>

            {leadData?.champion_details?.map((chap, index) => (
              <React.Fragment key={index}>
                <Grid size={12}>
                  <Typography variant="body2">
                    Champion Name {index + 1}
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    value={chap.name}
                    onChange={(e) =>
                      handleChampionChange(index, "name", e.target.value)
                    }
                  />
                </Grid>
                <Grid size={12}>
                  <Typography variant="body2">Job Title / Position</Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    value={chap.job_title}
                    onChange={(e) =>
                      handleChampionChange(index, "job_title", e.target.value)
                    }
                  />
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">Email</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">Contact Number</Typography>
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    value={chap.email}
                    onChange={(e) =>
                      handleChampionChange(index, "email", e.target.value)
                    }
                  />
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    value={chap.phone_number}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      ); // Allow only numbers
                      handleChampionChange(index, "phone_number", numericValue);
                    }}
                  />
                </Grid>
                <Grid size={12} sx={{ pt: 1 }}>
                  <Divider />
                </Grid>
              </React.Fragment>
            ))}
            <Grid size={2}>
              <IconButton
                title="Add Representative"
                onClick={handleAddChampion}
              >
                <PersonAddIcon />
              </IconButton>
            </Grid>
            <Grid size={10} alignContent={"center"}>
              <Typography variant="body2">Add Champion.</Typography>
            </Grid>

            {/* Contact Person */}
            <Grid size={12} sx={{ mt: 2 }}>
              <Typography variant="body">Contact Person</Typography>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
            {leadData?.contact_person?.map((cnt, index) => (
              <React.Fragment key={index}>
                <Grid size={12}>
                  <Typography variant="body2">
                    Contact Name {index + 1}
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    value={cnt.name}
                    onChange={(e) =>
                      handleContactChange(index, "name", e.target.value)
                    }
                  />
                </Grid>
                <Grid size={12}>
                  <Typography variant="body2">Job Title / Position</Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    value={cnt.job_title}
                    onChange={(e) =>
                      handleContactChange(index, "job_title", e.target.value)
                    }
                  />
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">Email</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">Contact Number</Typography>
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    value={cnt.email}
                    onChange={(e) =>
                      handleContactChange(index, "email", e.target.value)
                    }
                  />
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    value={cnt.phone_number}
                    type="text"
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      ); // Allow only numbers
                      handleContactChange(index, "phone_number", numericValue);
                    }}
                  />
                </Grid>
                <Grid size={12} sx={{ pt: 1 }}>
                  <Divider />
                </Grid>
              </React.Fragment>
            ))}

            <Grid size={2}>
              <IconButton title="Add Contact Person" onClick={handleAddContact}>
                <PersonAddIcon />
              </IconButton>
            </Grid>
            <Grid size={10} alignContent={"center"}>
              <Typography variant="body2">Add Contact Person.</Typography>
            </Grid>

            {/* Inter Action Logs */}
            <Grid size={12} sx={{ mt: 2 }}>
              <Typography variant="body">Interaction Logs</Typography>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
            {leadData?.interaction_logs?.map((log, index) => (
              <React.Fragment key={index}>
                <Grid size={12}>
                  <Typography variant="body2">Details {index + 1}</Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={2}
                    value={log.details}
                    onChange={(e) =>
                      handleLogChange(index, "details", e.target.value)
                    }
                  />
                </Grid>
              </React.Fragment>
            ))}
            <Grid size={2}>
              <IconButton title="Add Interaction Log" onClick={handleAddLog}>
                <CommentIcon />
              </IconButton>
            </Grid>
            <Grid size={10} alignContent={"center"}>
              <Typography variant="body2">Add Interaction Logs.</Typography>
            </Grid>

            <Grid size={12} container justifyContent="flex-end" sx={{ mt: 3 }}>
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={closeDrawer}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleSubmitLead}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>

      {/* Drawer for Editing Project/Leads */}
      <Drawer open={openEdit} onClose={() => closeDrawerEdit()}>
        <Box
          className="thin-scrollbar"
          sx={{ width: { xs: "auto", lg: 550, xl: 600 }, mt: 8, p: 2 }}
          role="presentation"
        >
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid size={9} display="flex" alignItems="center">
              <PeopleOutlineIcon />
              <Typography variant="body" sx={{ ml: 1 }}>
                Edit Leads
              </Typography>
            </Grid>
            <Grid
              size={3}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              {isMarketing && (
                <>
                  <Typography variant="caption" sx={{ ml: 1 }}>
                    Action Item
                  </Typography>
                  <IconButton
                    title="Action Item"
                    onClick={() => handleClickOpen()}
                  >
                    <ListAltIcon />
                  </IconButton>
                </>
              )}
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container spacing={0.5} columnSpacing={2} sx={{ mt: 2 }}>
            <Grid size={12}>
              <Typography variant="body2" sx={typographyStyles}>
                Project Name <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="project_name"
                value={leadData.project_name}
                onChange={handleChange}
                disabled={!isMarketing}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2" sx={typographyStyles}>
                Company / Organization <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="company_name"
                value={leadData.company_name}
                onChange={handleChange}
                disabled={!isMarketing}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2" sx={typographyStyles}>
                Implementing Agency
              </Typography>
            </Grid>

            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="implementing_agency"
                value={leadData.implementing_agency}
                onChange={handleChange}
                disabled={!isMarketing}
              />
            </Grid>
            {/* <Grid size={6}>
              <Typography variant="body2">Start Date</Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">End Date</Typography>
            </Grid>
            <Grid size={6}>
              <TextField fullWidth size="small" name="start_date" />
            </Grid>
            <Grid size={6}>
              <TextField fullWidth size="small" name="end_date" />
            </Grid> */}
            <Grid size={12}>
              <Typography variant="body2" sx={typographyStyles}>
                Estimated Value <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="estimated_value"
                value={leadData.estimated_value}
                onChange={handleChange}
                disabled={!isMarketing}
              />
            </Grid>
            <Grid size={6}>
              <Typography variant="body2" sx={typographyStyles}>
                Status
              </Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2" sx={typographyStyles}>
                Priority
              </Typography>
            </Grid>
            <Grid size={6}>
              <Select
                size="small"
                fullWidth
                name="status"
                value={leadData.status}
                onChange={handleChange}
                disabled={!isMarketing}
              >
                <MenuItem value="Outbound">Outbound</MenuItem>
                <MenuItem value="Inbound">Inbound</MenuItem>
                <MenuItem value="Qualified">Qualified</MenuItem>
                <MenuItem value="Proposal">Proposal</MenuItem>
                <MenuItem value="Negotiation">Negotiation</MenuItem>
                <MenuItem value="Closed">Closed</MenuItem>
                <MenuItem value="Lost">Lost</MenuItem>
                <MenuItem value="Backburner">Backburner</MenuItem>
              </Select>
            </Grid>
            <Grid size={6}>
              <Select
                size="small"
                fullWidth
                name="level_of_priority"
                value={leadData.level_of_priority}
                onChange={handleChange}
                disabled={!isMarketing}
              >
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="High">High</MenuItem>
              </Select>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2" sx={typographyStyles}>
                Company / Org. Email <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2" sx={typographyStyles}>
                Company / Org. Phone <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={6}>
              <TextField
                fullWidth
                size="small"
                name="company_email"
                value={leadData.company_email}
                onChange={handleChange}
                disabled={!isMarketing}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                fullWidth
                size="small"
                name="company_contact_number"
                value={leadData.company_contact_number}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                  handleChange({
                    target: { name: e.target.name, value: numericValue },
                  });
                }}
                type="text"
                disabled={!isMarketing}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2" sx={typographyStyles}>
                Industry
              </Typography>
            </Grid>
            <Grid size={12}>
              <Autocomplete
                disabled={!isMarketing}
                options={industry}
                getOptionLabel={(option) => option}
                value={leadData.nature_of_business}
                filterSelectedOptions
                onChange={handleSelectInterested}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2" sx={typographyStyles}>
                Product Interested In
              </Typography>
            </Grid>
            <Grid size={12}>
              <Autocomplete
                multiple
                value={leadData.product_interested_in}
                options={products}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                onChange={handleSelect}
                disabled={!isMarketing}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2" sx={typographyStyles}>
                Source
              </Typography>
            </Grid>
            <Grid size={12} sx={{ mb: 2 }}>
              <TextField
                fullWidth
                size="small"
                name="source"
                value={leadData.source}
                onChange={handleChange}
                disabled={!isMarketing}
              />
            </Grid>
            {/* <Grid size={9} textAlign={"right"}>
              Upload Logo <br />
              <Typography variant="body2">"No Image Selected"</Typography>
            </Grid>
            <Grid size={3}>
              <input
                type="file"
                accept="image/*"
                name="image"
                style={{ display: "none" }} // Hide the actual file input
              />
              <IconButton>
                <FileUploadIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Grid> */}

            {/* Champion */}
            <Grid size={12}>
              <Typography
                variant="body"
                sx={(typographyStyles, { fontWeight: "bold" })}
              >
                Champion
              </Typography>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>

            {leadData?.champion_details?.map((chap, index) => (
              <React.Fragment key={index}>
                <Grid size={12}>
                  <Typography variant="body2" sx={typographyStyles}>
                    Champion Name {index + 1}
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    value={chap.name}
                    disabled={!isMarketing}
                    onChange={(e) =>
                      handleChampionChange(index, "name", e.target.value)
                    }
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              title="Delete Champion"
                              disabled={!isMarketing}
                              onClick={() =>
                                handleDeleteChamp(leadData._id, chap._id)
                              }
                            >
                              <PersonRemoveIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </Grid>

                <Grid size={12}>
                  <Typography variant="body2" sx={typographyStyles}>
                    Job Title / Position
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    value={chap.job_title}
                    disabled={!isMarketing}
                    onChange={(e) =>
                      handleChampionChange(index, "job_title", e.target.value)
                    }
                  />
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2" sx={typographyStyles}>
                    Email
                  </Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2" sx={typographyStyles}>
                    Contact Number
                  </Typography>
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    value={chap.email}
                    disabled={!isMarketing}
                    onChange={(e) =>
                      handleChampionChange(index, "email", e.target.value)
                    }
                  />
                </Grid>
                <Grid size={6} sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    value={chap.phone_number}
                    disabled={!isMarketing}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      ); // Allow only numbers
                      handleChampionChange(index, "phone_number", numericValue);
                    }}
                  />
                </Grid>
                <Grid size={12} sx={{ pt: 1 }}>
                  <Divider />
                </Grid>
              </React.Fragment>
            ))}
            {isMarketing && (
              <>
                <Grid size={2}>
                  <IconButton title="Add Champion" onClick={handleAddChampion}>
                    <PersonAddIcon />
                  </IconButton>
                </Grid>
                <Grid size={10} alignContent={"center"}>
                  <Typography variant="body2" sx={typographyStyles}>
                    Add Champion.
                  </Typography>
                </Grid>
              </>
            )}
            {/* Contact Person */}
            <Grid size={12} sx={{ mt: 2 }}>
              <Typography
                variant="body"
                sx={(typographyStyles, { fontWeight: "bold" })}
              >
                Contact Person
              </Typography>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
            {leadData?.contact_person?.map((cnt, index) => (
              <React.Fragment key={index}>
                <Grid size={12}>
                  <Typography variant="body2" sx={typographyStyles}>
                    Contact Name {index + 1}
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    value={cnt.name}
                    disabled={!isMarketing}
                    onChange={(e) =>
                      handleContactChange(index, "name", e.target.value)
                    }
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              title="Delete Contact Person"
                              disabled={!isMarketing}
                              onClick={() =>
                                handleDeleteContact(leadData._id, cnt._id)
                              }
                            >
                              <PersonRemoveIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </Grid>

                <Grid size={12}>
                  <Typography variant="body2" sx={typographyStyles}>
                    Job Title / Position
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    value={cnt.job_title}
                    disabled={!isMarketing}
                    onChange={(e) =>
                      handleContactChange(index, "job_title", e.target.value)
                    }
                  />
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2" sx={typographyStyles}>
                    Email
                  </Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2" sx={typographyStyles}>
                    Contact Number
                  </Typography>
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    value={cnt.email}
                    disabled={!isMarketing}
                    onChange={(e) =>
                      handleContactChange(index, "email", e.target.value)
                    }
                  />
                </Grid>
                <Grid size={6}>
                  <TextField
                    fullWidth
                    size="small"
                    type="text"
                    value={cnt.phone_number}
                    disabled={!isMarketing}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      ); // Allow only numbers
                      handleContactChange(index, "phone_number", numericValue);
                    }}
                  />
                </Grid>
                <Grid size={12} sx={{ pt: 1 }}>
                  <Divider />
                </Grid>
              </React.Fragment>
            ))}
            {isMarketing && (
              <>
                <Grid size={2}>
                  <IconButton
                    title="Add Contact Person"
                    onClick={handleAddContact}
                  >
                    <PersonAddIcon />
                  </IconButton>
                </Grid>
                <Grid size={10} alignContent={"center"}>
                  <Typography variant="body2">Add Contact Person.</Typography>
                </Grid>
              </>
            )}
            {/* Inter Action Logs */}
            <Grid size={12} sx={{ mt: 2 }}>
              <Typography variant="body" sx={{ fontWeight: "bold" }}>
                Interaction Logs
              </Typography>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
            {leadData?.interaction_logs?.map((log, index) => (
              <React.Fragment key={index}>
                <Grid size={12}>
                  <Typography variant="body2">Details {index + 1}</Typography>
                </Grid>
                <Grid size={12}>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    // rows={3}
                    value={log.details}
                    onChange={(e) =>
                      handleLogChange(index, "details", e.target.value)
                    }
                  />
                </Grid>
              </React.Fragment>
            ))}
            {isMarketing && (
              <>
                <Grid size={2}>
                  <IconButton
                    title="Add Interaction Log"
                    onClick={handleAddLog}
                  >
                    <CommentIcon />
                  </IconButton>
                </Grid>
                <Grid size={10} alignContent={"center"}>
                  <Typography variant="body2">Add Interaction Logs.</Typography>
                </Grid>

                <Grid
                  size={12}
                  container
                  justifyContent="flex-end"
                  sx={{ mt: 3 }}
                >
                  <IconButton title="Delete" onClick={() => handleDeleteLead()}>
                    <DeleteForeverIcon />
                  </IconButton>
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={closeDrawerEdit}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleUpdateLead}
                  >
                    Update
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Drawer>

      {/* Dialog for Adding and Viewing Action Item */}
      <Dialog open={openDialog} fullWidth maxWidth={"md"}>
        <DialogTitle>{"Action Item"}</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent={"center"}
            sx={{ mb: 2, mt: 1 }}
          >
            <Grid size={{ xs: 12, lg: 9 }} display="flex" alignItems="center">
              {employees && (
                <Autocomplete
                  multiple
                  options={employees}
                  getOptionLabel={(option) =>
                    option.firstname + " " + option.lastname
                  }
                  onChange={handleSelectEmployee}
                  filterSelectedOptions
                  fullWidth
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Employee"
                      placeholder="Name"
                      required
                    />
                  )}
                />
              )}
            </Grid>
            <Grid size={{ xs: 12, lg: 3 }} display="flex" alignItems="center">
              <TextField
                fullWidth
                size="small"
                label="Deadline"
                type="date"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                value={deadline}
                onChange={(e) => setDeadline(e.target.value)}
              />
            </Grid>
            <Grid size={12} display="flex" alignItems="center">
              <TextField
                fullWidth
                multiline
                size="small"
                label="Details"
                required
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              />
            </Grid>
            <Grid size={12} display="flex" alignItems="center">
              <TableContainer
                component={Paper}
                sx={{ maxHeight: 400 }}
                className="thin-scrollbar"
              >
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="right"></TableCell>
                      <TableCell align="left" sx={{ fontWeight: "bold" }}>
                        Details
                      </TableCell>
                      <TableCell align="left" sx={{ fontWeight: "bold" }}>
                        Deadline
                      </TableCell>
                      <TableCell align="left" sx={{ fontWeight: "bold" }}>
                        Status
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leadData?.action_items
                      ?.sort((a, b) => Number(a.is_done) - Number(b.is_done))
                      ?.map((itm, index) => (
                        <TableRow
                          key={index}
                          className="table-row"
                          aria-owns={open ? "mouse-over-popover" : undefined}
                          aria-haspopup="true"
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        >
                          <TableCell align="left">
                            Action Item {index + 1}
                          </TableCell>
                          <TableCell align="left">{itm.details}</TableCell>
                          <TableCell align="left">
                            {moment(itm.deadline).format("LL")}{" "}
                          </TableCell>
                          <TableCell align="left">
                            {itm.is_done === true ? (
                              <Chip
                                size="small"
                                color="success"
                                label={"Done"}
                              />
                            ) : (
                              <Chip
                                size="small"
                                color="warning"
                                label={"Pending"}
                              />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <IconButton
                              title="Delete"
                              size="small"
                              onClick={() => handleDeleteItem(itm._id)}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                            <IconButton
                              title="Done"
                              size="small"
                              onClick={() => handleUpdateItem(itm._id)}
                            >
                              <CheckCircleOutlineIcon />
                            </IconButton>
                          </TableCell>
                          <Popover
                            anchorOrigin={{
                              vertical: "center",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "center",
                              horizontal: "right",
                            }}
                            id="mouse-over-popover"
                            sx={{ pointerEvents: "none" }}
                            open={openpop}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                          >
                            <Typography sx={{ p: 1 }}>
                              Employee Involved.
                            </Typography>
                            {itm?.employees_involved?.map((emp, index) => (
                              <>
                                <Chip
                                  size="small"
                                  sx={{ m: 1 }}
                                  label={emp.lastname}
                                />
                              </>
                            ))}
                          </Popover>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={saveActionItem} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Project;
