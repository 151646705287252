import axios from "axios";

export const createCompnay = async (eventId, data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/special-event-visitors/add-company-org?eventId=${eventId}`,
      data
    );

    return response.data; // Return the response data for further use
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

export const fetchCompanies = async (eventId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/special-event-visitors/all?eventId=${eventId}`
    );
    // console.log(response.data.visitors);
    return response.data.visitors;
  } catch (error) {
    throw new Error(
      "An error occurred while fetching events. Please try again later."
    );
  }
};

export const viewDetails = async (eventId, orgId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/special-event-visitors/org-details?eventId=${eventId}&companyId=${orgId}`
    );
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while saving the event.");
  }
};

export const editCompany = async (eventId, orgId, data) => {
  // console.log(eventId, orgId, data);
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/special-event-visitors/edit-company-org?eventId=${eventId}&orgId=${orgId}`,
      data
    );

    // return response;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

export const deleteCompany = async (eventId, orgId) => {
  // console.log(eventId, orgId);
  try {
    await axios.patch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/special-event-visitors/delete-company-org?eventId=${eventId}`,
      { orgId }
    );
  } catch (error) {
    console.log(error);
    throw new Error(error.response.data.error);
  }
};

// Get all visitors from previous and upcoming events
export const fetchAllVisitors = async (eventId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/get-all-visitors`
    );
    return response.data.visitors;
  } catch (error) {
    throw new Error(
      "An error occurred while fetching events. Please try again later."
    );
  }
};

// Specific Company
export const fetchAttendees = async (eventID, orgId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/special-event-visitors/company-all-attendees?eventId=${eventID}&companyId=${orgId}`
    );
    console.log(response.data)
    return response.data.visitors; // Return the response data for further use
  } catch (error) {
    throw new Error("An error occurred while saving the event.");
  }
};

export const addMultipleAttendees = async (eventId, orgId, data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/special-event-visitors/add-attendees?eventId=${eventId}&orgId=${orgId}`,
      data
    );

    return response.data; // Return the response data for further use
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

export const sendRegLink = async (eventId, orgId, data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/special-event-visitors/self/add-link?eventId=${eventId}&companyId=${orgId}`,
      data
    );

    return response.data; // Return the response data for further use
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

export const resendRegLink = async (eventId, orgId, data) => {
  // console.log( data)
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/special-event-visitors/self/resend-atnd-link?eventId=${eventId}&companyId=${orgId}`,
      data
    );

    return response.data; // Return the response data for further use
  } catch (error) {
    console.log(error)
    throw new Error(error.response.data.error);
  }
};

export const sendRegLinkRep = async (eventId, orgId, visitorId, data) => {
  // console.log(data)
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/special-event-visitors/self/add-reprs-link?eventId=${eventId}&companyId=${orgId}&visitorId=${visitorId}`,
      data
    );

    return response.data; // Return the response data for further use
  } catch (error) {
    console.log(error)
    throw new Error(error.response.data.error);
  }
};

export const resendRepRegLink = async (eventId, orgId, data) => {
  console.log( data)
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/events/special-event-visitors/self/resend-reprs-link?eventId=${eventId}&companyId=${orgId}`,
      data
    );

    return response.data; // Return the response data for further use
  } catch (error) {
    console.log(error)
    throw new Error(error.response.data.error);
  }
};