import React, { useState } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import Grid from "@mui/material/Grid2";
import {
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  TextField,
  Divider,
} from "@mui/material";
import { scanQR, validateQR } from "../../services/eventsAPI"; //API call
import { Toast, confirmDialog } from "../../utils/Alert";
import { useParams } from "react-router-dom";

import StarRateIcon from "@mui/icons-material/StarRate";

function QR() {
  const { eventID } = useParams(); // Get the ID from the URL (event ID)
  const [cameraAvailable, setCameraAvailable] = useState(false);

  const notification = (icon, message) => {
    Toast.fire({
      icon: icon,
      title: message,
    });
  };

  React.useEffect(() => {
    // Check if any video input devices (cameras) are available
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const hasCamera = devices.some(
          (device) => device.kind === "videoinput"
        );
        setCameraAvailable(hasCamera);
      })
      .catch((err) => {
        console.error("Error checking for cameras:", err);
      });
  }, []);

  // Scanning the QR code and displaying the attendee info
  const [attendeeData, setAttendeeData] = React.useState([]);
  const [visitorID, setVisitorID] = React.useState();
  const [orgID, setOrgID] = React.useState();

  const handleScan = async (data) => {
    try {
      const qrValue = data[0]?.rawValue; // Safely access rawValue in case data[0] is undefined
      if (!qrValue) {
        notification("error", "Invalid QR code data.");
        return;
      }
      // Extract the first value (eventID) from the QR code data
      const selected_attendee = await validateQR(qrValue, eventID); //get the details of the attendee for display
      setAttendeeData(selected_attendee);
      setVisitorID(selected_attendee.visitorId);
      setOrgID(selected_attendee.companyId);
      handleClickOpen();
    } catch (error) {
      console.error("Error handling QR scan:", error.message);
    }
  };

  const ConfirmAttendee = async () => {
    const response = await scanQR(visitorID, eventID, orgID);
    notification("success", "Attendee Confirmed");
    handleClose();
    if (!response) {
      notification("error", "No data found for the scanned QR code.");
      return;
    }
  };

  // Dialog_______________________
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <>
      {cameraAvailable ? (
        <>
          <Typography variant="h4" sx={{ mt: 3, pb: 2 }}>
            Scan QR Code
          </Typography>
          <Box
            sx={{
              margin: "auto",
              textAlign: "center",
              width: { xs: "90%", sm: "80%", md: 400 }, // Responsive width
              maxWidth: 400, // Set a max width to prevent stretching on larger screens
            }}
          >
            <Scanner
              onScan={handleScan}
              onError={(err) => console.error("QR Scan Error:", err)}
              scanDelay={1000}
            />
            <Typography variant="body1" sx={{ paddingTop: 2, p: 2 }}>
              <b>Ensure Proper Lighting: </b>For better scanning, make sure the
              environment has good lighting and avoid glare on the QR code.
              <br />
              <b>Maintain Device Distance: </b>Hold your device at an
              appropriate distance from the QR code for the scanner to read it
              clearly.
              <br />
            </Typography>
          </Box>
        </>
      ) : (
        <p>No camera detected. Please connect a camera and try again.</p>
      )}

      {/* Display Attende Info Dialog */}
      <Dialog open={openDialog} fullWidth maxWidth={"md"}>
        <DialogTitle>
          {"Confirm Attendee"}{" "}
          {attendeeData.vip === true ? <StarRateIcon /> : ""}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid size={12} sx={{ mt: 2 }}>
              <TextField
                size="small"
                disabled
                value={attendeeData.visitor_name}
                label="Name"
                fullWidth
              />
            </Grid>
            <Grid size={12}>
              <TextField
                size="small"
                disabled
                value={attendeeData.job_title}
                label="Role / Position"
                fullWidth
              />
            </Grid>
            <Grid size={12}>
              <TextField
                size="small"
                disabled
                value={attendeeData.company_name}
                label="Company Name"
                fullWidth
              />
            </Grid>
            {attendeeData.is_representative && (
              <Grid size={12}>
                <Typography variant="body3">Representative</Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={ConfirmAttendee}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default QR;
