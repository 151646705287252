import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Select,
  MenuItem,
  Drawer,
  IconButton,
  Stack,
  Chip,
} from "@mui/material";
import {
  fetchVIPs,
  deleteAttendee,
  deleteBoothAttendee,
  getAttendeeDetails,
  getBoothAttendeeDetails,
  editAttendeeDets,
  editBoothAttendeeDets,
} from "../../services/eventsAPI"; //API call
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { filterAttendees } from "../../utils/Filter";
import { Toast, confirmDialog } from "../../utils/Alert";
import { addleads } from "../../services/leadsAPI";

// icons
import SearchIcon from "@mui/icons-material/Search";
import EventIcon from "@mui/icons-material/Event";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// Table columns
const columns = [
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "level_of_interest",
    label: "Interest",
    minWidth: 50,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  { id: "name", label: "Name", minWidth: 170 },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  {
    id: "contact",
    label: "Contact",
    minWidth: 130,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
  { id: "company", label: "Company/Organization", minWidth: 225 },
  {
    id: "event",
    label: "Event",
    minWidth: 225,
    display: {
      xs: "none",
      sm: "table-cell",
    },
  },
];

function VIP({ isMarketing, user }) {
  const [searchTerm, setSearchTerm] = useState("");
  const queryClient = useQueryClient();

  // Use React Query's useQuery to fetch events
  const { isLoading: loadingVips, data: VIPs } = useQuery({
    queryKey: ["VIPs"], // Query key for caching and invalidation
    queryFn: fetchVIPs, // Function to fetch data
  });

  // Handle change page
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const notification = (icon, message) => {
    Toast.fire({
      icon: icon,
      title: message,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setAttendeeData((prevState) => ({
      ...prevState,
      [name]: name === "vip" ? value === "true" : value, // Convert "true"/"false" to boolean for vip
    }));
  };

  // Attendee data
  const [attendeeData, setAttendeeData] = useState({
    // state for attendee data
    visitor_name: "",
    vip: false,
    email: "",
    contact_number: "",
    status: "",
    level_of_interest: "",
    job_title: "",
    company_name: "",
    company_email: "",
    company_contact_number: "",
    nature_of_business: "",
    notes: "",
    special_event: "",
    company_id: "",
    representatives: [],
  });

  // edit attendee section
  const [attendeeID, setAttendeeID] = useState(); // selected attendee ID
  const [eventID, setEventID] = useState();

  const editAttendee = async (
    attendee_ID,
    eventID,
    eventName,
    _isSpecialEvent,
    _compID
  ) => {
    setOpenEdit(true);
    setAttendeeID(attendee_ID);

    // Call the appropriate function based on _isSpecialEvent
    const selected_attendee = _isSpecialEvent
      ? await getAttendeeDetails(attendee_ID, eventID, _compID)
      : await getBoothAttendeeDetails(attendee_ID, eventID);

    const updatedAttendeeData = {
      ...selected_attendee,
      eventName,
      special_event: _isSpecialEvent, //add data special_event to attendee_data
    };

    setAttendeeData(updatedAttendeeData);
    setEventID(eventID);
  };

  // Editing Attendee Mutation
  const editAttendeemutation = useMutation({
    mutationFn: ({ attendee_ID, _compID, _isSpecialEvent }) =>
      _isSpecialEvent
        ? editAttendeeDets(attendee_ID, eventID, _compID, attendeeData)
        : editBoothAttendeeDets(attendee_ID, eventID, attendeeData),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["VIPs"]);
      notification("success", "Attendee updated!");
    },
    onError: (error) => {
      console.error(error);
      notification("error", "An error occurred while saving the event.");
    },
  });

  const handleEditAttendee = (attendee_ID, _compID, _isSpecialEvent) => {
    // Check if any field in formData is empty
    if (
      attendeeData.name === "" ||
      attendeeData.email === "" ||
      attendeeData.level_of_interest === "" ||
      attendeeData.contact_number === ""
      // attendeeData.status === ""
    ) {
      notification("error", "Fill in required fields.");
      return;
    }
    editAttendeemutation.mutate({ attendee_ID, _compID, _isSpecialEvent });
  };

  // Add Attendee Drawer
  const [openEdit, setOpenEdit] = React.useState(false);
  //closes drawer and clear attendee data
  const closeDrawerEdit = () => {
    setOpenEdit(false);
    setAttendeeData({
      name: "",
      vip: false,
      email: "",
      contact_number: "",
      status: "",
      level_of_interest: "",
      job_title: "",
      company_name: "",
      company_email: "",
      company_contact_number: "",
      nature_of_business: "",
      notes: "",
      special_event: "",
      company_id: "",
      representatives: [],
    });
  };

  // Delete Attendee______________________
  const deleteAttendeeMutation = useMutation({
    mutationFn: ({ _compID, _isSpecialEvent }) =>
      _isSpecialEvent
        ? deleteAttendee(attendeeID, eventID, _compID)
        : deleteBoothAttendee(attendeeID, eventID),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["VIPs"]);
      notification("success", "Attendee successfully deleted");
    },
  });

  const handleDeleteAttendee = async (_compID, _isSpecialEvent) => {
    const userConfirmed = await confirmDialog({
      title: "Delete attendee?",
      text: "This action cannot be undone.",
    });
    if (userConfirmed) {
      deleteAttendeeMutation.mutate({ _compID, _isSpecialEvent });
    }
  };

  // Adding more representative
  const handleAddRepresentative = () => {
    // Add a new representative group
    setAttendeeData((prevState) => ({
      ...prevState,
      representatives: [
        ...prevState.representatives,
        { name: "", email: "", contact_number: "", company_name: "", role: "" },
      ],
    }));
  };

  const handleRepresentativeChange = (index, field, value) => {
    // Update the specific representative's field
    const updatedReps = [...attendeeData.representatives];
    updatedReps[index][field] = value;
    setAttendeeData((prevState) => ({
      ...prevState,
      representatives: updatedReps,
    }));
  };

  // Copy to leads

  const [toLeads, setToLeads] = useState([]);
  const copytoLeads = async () => {
    setToLeads({
      project_name: "Event:" + attendeeData.eventName,
      company_name: attendeeData.company_name,
      implementing_agency: "",
      company_email: attendeeData.company_email,
      company_contact_number: attendeeData.company_contact_number,
      estimated_value: "10",
      nature_of_business: attendeeData.nature_of_business,
      interaction_logs: [],
      action_items: [],
      status: "Outbound",
      level_of_priority: "",
      product_interested_in: [],
      source: attendeeData.eventName,
      contact_person: [],
      champion_details: [
        {
          name: attendeeData.visitor_name,
          email: attendeeData.email,
          phone_number: attendeeData.contact_number,
          job_title: attendeeData.job_title,
        },
      ],
      user: user,
    });

    const userConfirmed = await confirmDialog({
      title: "Copy attendee?",
      text: "Details will be copy to Leads",
    });
    if (userConfirmed) {
      copyLeadmutation.mutate();
    }
  };

  // Copy attendeed to Leads
  const copyLeadmutation = useMutation({
    mutationFn: () => addleads(toLeads),
    onSuccess: () => {
      closeDrawerEdit();
      queryClient.invalidateQueries(["leads"]);
      notification("success", "Copied Successfully !");
    },
    onError: (error) => {
      console.log(error);
      notification("error", error.response.data.error);
    },
  });

  const asteriskStyle = { color: "#c20a0a" };

  if (loadingVips || loadingVips) return <div>Loading...</div>; //loading state
  const filteredData = filterAttendees(VIPs, searchTerm);
  return (
    <>
      {/* Main */}
      <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ mb: 2 }} variant="h5">
          VIPs
        </Typography>
        <Grid container spacing={2}>
          <Grid
            size={12}
            container
            justifyContent="flex-start"
            alignItems={"center"}
          >
            <TextField
              label="Search"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
          <Grid size={12} sx={{ mb: 2 }}>
            <Divider />
          </Grid>
          <Grid size={12} sx={{ mb: 2 }}>
            {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
            <TableContainer sx={{ width: "100%", overflow: "hidden" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        sx={{
                          display: column.display || "table-cell",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingVips ? (
                    // Display a loading indicator properly wrapped in table elements
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow
                          key={row.attendee_id}
                          onClick={() =>
                            editAttendee(
                              row.attendee_id,
                              row._id,
                              row.event_name,
                              row.special_event,
                              row.company_id
                            )
                          }
                          className="table-row"
                        >
                          <TableCell
                            align="left"
                            sx={{
                              display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              },
                            }}
                          >
                            <Stack direction="row">
                              <Chip
                                size="small"
                                color={
                                  row.status === "Confirmed"
                                    ? "primary"
                                    : row.status === "No-show"
                                      ? "secondary"
                                      : row.status === "Attended"
                                        ? "success"
                                        : row.status === "Rejected"
                                          ? "error"
                                          : "default" // or any other default color
                                }
                                label={row.status || "N/A"}
                              />
                            </Stack>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              },
                            }}
                          >
                            {row.level_of_interest}
                          </TableCell>
                          <TableCell align="left">{row.visitor_name}</TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              },
                            }}
                          >
                            {row.email}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              },
                            }}
                          >
                            {row.contact_number}
                          </TableCell>
                          <TableCell align="left">{row.company_name}</TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              display: {
                                xs: "none", // Hide on extra-small screens
                                sm: "table-cell", // Show on small screens and larger
                              },
                            }}
                          >
                            {row.event_name}
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={VIPs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* </Paper> */}
          </Grid>
        </Grid>
      </Box>

      {/* Drawer for Editing VIP */}
      <Drawer open={openEdit} onClose={() => closeDrawerEdit()}>
        <Box
          className="thin-scrollbar"
          sx={{ width: { xs: 360, lg: 480, xl: 550 }, mt: 8, p: 2 }}
          role="presentation"
        >
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid size={12} display="flex" alignItems="center">
              <EventIcon />
              <Typography variant="body" sx={{ ml: 1 }}>
                Edit Attendee
              </Typography>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid container rowSpacing={0.5} columnSpacing={1} sx={{ mt: 1 }}>
            <Grid size={10}>
              <Typography variant="body2">
                Name <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={2}>
              <Typography variant="body2">VIP?</Typography>
            </Grid>
            <Grid size={9}>
              <TextField
                fullWidth
                size="small"
                name="visitor_name"
                value={attendeeData.visitor_name || ""}
                onChange={handleChange}
                disabled={!isMarketing}
              />
            </Grid>
            <Grid size={3}>
              <Select
                size="small"
                fullWidth
                name="vip"
                value={String(attendeeData.vip)} // Convert boolean to string for the Select component
                onChange={handleChange}
                disabled={!isMarketing}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">
                Status <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">
                Level of Interest <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>

            <Grid size={6}>
              <Select
                size="small"
                fullWidth
                name="status"
                value={attendeeData.status}
                onChange={handleChange}
                // disabled={!isMarketing}
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Confirmed">Confirmed</MenuItem>
                <MenuItem value="Unconfirmed">Unconfirmed</MenuItem>
                <MenuItem value="Attended">Attended</MenuItem>
                <MenuItem value="No-show">No-show</MenuItem>
              </Select>
            </Grid>

            <Grid size={6}>
              <Select
                size="small"
                fullWidth
                name="level_of_interest"
                value={attendeeData.level_of_interest}
                onChange={handleChange}
                disabled={!isMarketing}
              >
                <MenuItem value="Potential">Potential</MenuItem>
                <MenuItem value="Casual">Casual</MenuItem>
              </Select>
            </Grid>

            <Grid size={12}>
              <Typography variant="body2">Role / Position</Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="job_title"
                value={attendeeData.job_title || ""}
                onChange={handleChange}
                disabled={!isMarketing}
              />
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">
                Email <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">
                Phone <span style={asteriskStyle}>*</span>
              </Typography>
            </Grid>
            <Grid size={6}>
              <TextField
                fullWidth
                size="small"
                name="email"
                value={attendeeData.email}
                onChange={handleChange}
                disabled={!isMarketing}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                fullWidth
                size="small"
                name="contact_number"
                value={attendeeData.contact_number}
                onChange={handleChange}
                disabled={!isMarketing}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">Company / Organization</Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="company_name"
                value={attendeeData.company_name || ""}
                onChange={handleChange}
                disabled={!isMarketing}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">Industry</Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                name="nature_of_business"
                value={attendeeData.nature_of_business || ""}
                onChange={handleChange}
                disabled={!isMarketing}
              />
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">Company / Org. Email</Typography>
            </Grid>
            <Grid size={6}>
              <Typography variant="body2">Company / Org. Phone</Typography>
            </Grid>
            <Grid size={6}>
              <TextField
                fullWidth
                size="small"
                name="company_email"
                value={attendeeData.company_email || ""}
                onChange={handleChange}
                disabled={!isMarketing}
              />
            </Grid>
            <Grid size={6}>
              <TextField
                fullWidth
                size="small"
                name="company_contact_number"
                value={attendeeData.company_contact_number || ""}
                onChange={handleChange}
                disabled={!isMarketing}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="body2">Note</Typography>
            </Grid>
            <Grid size={12}>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={3}
                disabled={!isMarketing}
                name="notes"
                value={attendeeData.notes}
                onChange={handleChange}
              />
            </Grid>

            {attendeeData?.representatives?.length > 0 ? (
              attendeeData.representatives.map((rep, index) => (
                <>
                  <Grid item size={12} sx={{ mt: 2, mb: 2 }}>
                    <Divider />
                  </Grid>
                  <Grid item size={12}>
                    <Typography variant="body2">Representative Name</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={rep.name}
                      onChange={(e) =>
                        handleRepresentativeChange(
                          index,
                          "name",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item size={12}>
                    <Typography variant="body2">Role / Position</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={rep.role}
                      onChange={(e) =>
                        handleRepresentativeChange(
                          index,
                          "role",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item size={6}>
                    <Typography variant="body2">Email</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={rep.email}
                      onChange={(e) =>
                        handleRepresentativeChange(
                          index,
                          "email",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item size={6}>
                    <Typography variant="body2">Phone Number</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      value={rep.contact_number}
                      onChange={(e) =>
                        handleRepresentativeChange(
                          index,
                          "contact_number",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                </>
              ))
            ) : (
              <></>
            )}

            {isMarketing && (
              <>
                <Grid size={2}>
                  <IconButton
                    title="Add Representative"
                    onClick={handleAddRepresentative}
                  >
                    <PersonAddAltIcon />
                  </IconButton>
                </Grid>
                <Grid size={10} alignContent={"center"}>
                  <Typography variant="body2">
                    Click to add Representative/s.
                  </Typography>
                </Grid>
                <Grid
                  size={12}
                  container
                  justifyContent="flex-end"
                  sx={{ mt: 3 }}
                >
                  <IconButton
                    title="Delete"
                    onClick={() =>
                      handleDeleteAttendee(
                        attendeeData.company_id,
                        attendeeData.special_event
                      )
                    }
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                  <Button
                    variant="contained"
                    size="small"
                    color="outboundColor"
                    onClick={copytoLeads}
                    sx={{ mr: 1 }}
                  >
                    Copy to Leads
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={closeDrawerEdit}
                    sx={{ mr: 1 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() =>
                      handleEditAttendee(
                        attendeeData.attendee_id,
                        attendeeData.company_id,
                        attendeeData.special_event
                      )
                    }
                  >
                    Update
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Drawer>
    </>
  );
}

export default VIP;
