import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from "@mui/material";

// icons
import SearchIcon from "@mui/icons-material/Search";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function Planning() {
  return (
    <>
      {/* Events Main Box */}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid size={8}>
            <Typography sx={{ mb: 2 }} variant="h5">
              Event Planning (v2)
            </Typography>
          </Grid>
          <Grid
            size={4}
            container
            justifyContent="flex-end"
            alignItems={"center"}
          >
            <TextField
              label="Search"
              size="small"
              // value={searchTerm}
              // onChange={(e) => setSearchTerm(e.target.value)}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
          <Grid size={12} sx={{ mb: 2 }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container size={4} spacing={2}>
          <Grid size={{ xs: 6, sm: 4, lg: 3, xl: 3 }}>
            <Card sx={{ maxWidth: 435 }}>
              <Box
                sx={{
                  cursor: "pointer",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "scale(1.02)",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                  },
                }}
                //   onClick={() => navigate(`/event/${event._id}/${event.name}`)}
              >
                <CardHeader
                  sx={{ height: 90 }}
                  title={
                    <Typography
                      variant="body"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      ADAS Private Luncheon
                    </Typography>
                  }
                  subheader={
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "0.875rem", fontStyle: "italic" }}
                    >
                      January 12 - January 15, 2025
                    </Typography>
                  }
                />
                <CardContent
                  sx={{
                    height: 60,
                    p: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressWithLabel value={50} />
                  </Box>
                </CardContent>
              </Box>
            </Card>
          </Grid>
          <Grid size={{ xs: 6, sm: 4, lg: 3, xl: 3 }}>
            <Card sx={{ maxWidth: 435 }}>
              <Box
                sx={{
                  cursor: "pointer",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "scale(1.02)",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                  },
                }}
                //   onClick={() => navigate(`/event/${event._id}/${event.name}`)}
              >
                <CardHeader
                  sx={{ height: 90 }}
                  title={
                    <Typography
                      variant="body"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      ADAS Booth
                    </Typography>
                  }
                  subheader={
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "0.875rem", fontStyle: "italic" }}
                    >
                      January 12 - January 15, 2025
                    </Typography>
                  }
                />
                <CardContent
                  sx={{
                    height: 60,
                    p: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressWithLabel value={100} />
                  </Box>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Planning;
